import ProfileAPI from "./../../common/api/service/ProfileService";
import * as actionTypes from "./actionTypes";

export const getMyProfileTypesStart = () => {
    return {
        type: actionTypes.GET_MY_PROFILE_TYPES_START,
    };
};

export const getMyProfileTypesSuccess = (profileTypes) => {
    return {
        type: actionTypes.GET_MY_PROFILE_TYPES_SUCCESS,
        profileTypes,
    };
};

export const getMyProfileTypesFail = (error) => {
    return {
        type: actionTypes.GET_MY_PROFILE_TYPES_FAIL,
        error: error,
    };
};

export const getMyProfileTypes = () => {
    return (dispatch) => {
        dispatch(getMyProfileTypesStart());

        return ProfileAPI.getMyProfileTypes()
            .then((res) => {
                dispatch(getMyProfileTypesSuccess(res));
            })
            .catch((err) => {
                dispatch(getMyProfileTypesFail(err));
            });
    };
};

export const getManagedPeopleProfileTypesStart = () => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_PROFILE_TYPES_START,
    };
};

export const getManagedPeopleProfileTypesSuccess = (profileTypes) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_PROFILE_TYPES_SUCCESS,
        profileTypes,
    };
};

export const getManagedPeopleProfileTypesFail = (error) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_PROFILE_TYPES_FAIL,
        error: error,
    };
};

export const getManagedPeopleProfileTypes = (email) => {
    return (dispatch) => {
        dispatch(getManagedPeopleProfileTypesStart());

        return ProfileAPI.getManagedPeopleProfileTypes(email)
            .then((res) => {
                dispatch(getManagedPeopleProfileTypesSuccess(res));
            })
            .catch((err) => {
                dispatch(getManagedPeopleProfileTypesFail(err));
            });
    };
};

export const getMyProfileVersionsStart = () => {
    return {
        type: actionTypes.GET_MY_PROFILE_VERSIONS_START,
    };
};

export const getMyProfileVersionsSuccess = (profileVersions) => {
    return {
        type: actionTypes.GET_MY_PROFILE_VERSIONS_SUCCESS,
        profileVersions,
    };
};

export const getMyProfileVersionsFail = (error) => {
    return {
        type: actionTypes.GET_MY_PROFILE_VERSIONS_FAIL,
        error: error,
    };
};

export const getMyProfileVersions = (profileType) => {
    return (dispatch) => {
        dispatch(getMyProfileVersionsStart());

        return ProfileAPI.getMyProfileVersions(profileType)
            .then((res) => {
                dispatch(getMyProfileVersionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getMyProfileVersionsFail(err));
            });
    };
};

export const getManagedPeopleProfileVersionsStart = () => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_PROFILE_VERSIONS_START,
    };
};

export const getManagedPeopleProfileVersionsSuccess = (profileVersions) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_PROFILE_VERSIONS_SUCCESS,
        profileVersions,
    };
};

export const getManagedPeopleProfileVersionsFail = (error) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_PROFILE_VERSIONS_FAIL,
        error: error,
    };
};

export const getManagedPeopleProfileVersions = (email, profileType) => {
    return (dispatch) => {
        dispatch(getManagedPeopleProfileVersionsStart());

        return ProfileAPI.getManagedPeopleProfileVersions(email, profileType)
            .then((res) => {
                dispatch(getManagedPeopleProfileVersionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getManagedPeopleProfileVersionsFail(err));
            });
    };
};

export const getMyLorProfileStart = () => {
    return {
        type: actionTypes.GET_MY_LOR_PROFILE_START,
    };
};

export const getMyLorProfileSuccess = (lorProfiles) => {
    return {
        type: actionTypes.GET_MY_LOR_PROFILE_SUCCESS,
        lorProfiles,
    };
};

export const getMyLorProfileFail = (error) => {
    return {
        type: actionTypes.GET_MY_LOR_PROFILE_FAIL,
        error: error,
    };
};

export const getMyLorProfile = (sfiaVersion, version, profileType) => {
    return (dispatch) => {
        dispatch(getMyLorProfileStart());

        return ProfileAPI.getMyLorPofiles(sfiaVersion, version, profileType)
            .then((res) => {
                dispatch(getMyLorProfileSuccess(res));
            })
            .catch((err) => {
                dispatch(getMyLorProfileFail(err));
            });
    };
};

export const getManagedPeopleLorProfileStart = () => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_LOR_PROFILE_START,
    };
};

export const getManagedPeopleLorProfileSuccess = (lorProfiles) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_LOR_PROFILE_SUCCESS,
        lorProfiles,
    };
};

export const getManagedPeopleLorProfileFail = (error) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_LOR_PROFILE_FAIL,
        error: error,
    };
};

export const getManagedPeopleLorProfile = (email, sfiaVersion, version, profileType) => {
    return (dispatch) => {
        dispatch(getManagedPeopleLorProfileStart());

        return ProfileAPI.getManagedPeopleLorPofiles(email, sfiaVersion, version, profileType)
            .then((res) => {
                dispatch(getManagedPeopleLorProfileSuccess(res));
            })
            .catch((err) => {
                dispatch(getManagedPeopleLorProfileFail(err));
            });
    };
};

export const getMySkillProfileStart = () => {
    return {
        type: actionTypes.GET_MY_SKILL_PROFILE_START,
    };
};

export const getMySkillProfileSuccess = (skillProfiles) => {
    return {
        type: actionTypes.GET_MY_SKILL_PROFILE_SUCCESS,
        skillProfiles,
    };
};

export const getMySkillProfileFail = (error) => {
    return {
        type: actionTypes.GET_MY_SKILL_PROFILE_FAIL,
        error: error,
    };
};

export const getMySkillProfile = (sfiaVersion, version, profileType) => {
    return (dispatch) => {
        dispatch(getMySkillProfileStart());

        return ProfileAPI.getMySkillPofiles(sfiaVersion, version, profileType)
            .then((res) => {
                //console.log('res', res);
                dispatch(getMySkillProfileSuccess(res));
            })
            .catch((err) => {
                dispatch(getMySkillProfileFail(err));
            });
    };
};

export const getManagedPeopleSkillProfileStart = () => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_SKILL_PROFILE_START,
    };
};

export const getManagedPeopleSkillProfileSuccess = (skillProfiles) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_SKILL_PROFILE_SUCCESS,
        skillProfiles,
    };
};

export const getManagedPeopleSkillProfileFail = (error) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_SKILL_PROFILE_FAIL,
        error: error,
    };
};

export const getManagedPeopleSkillProfile = (email, sfiaVersion, version, profileType) => {
    return (dispatch) => {
        dispatch(getManagedPeopleSkillProfileStart());

        return ProfileAPI.getManagedPeopleSkillPofiles(email, sfiaVersion, version, profileType)
            .then((res) => {
                //console.log('res', res);
                dispatch(getManagedPeopleSkillProfileSuccess(res));
            })
            .catch((err) => {
                dispatch(getManagedPeopleSkillProfileFail(err));
            });
    };
};

export const printMyProfileStart = () => {
    return {
        type: actionTypes.PRINT_MY_PROFILE_START,
    };
};

export const printMyProfileSuccess = (printData) => {
    return {
        type: actionTypes.PRINT_MY_PROFILE_SUCCESS,
        printData,
    };
};

export const printMyProfileFail = (error) => {
    return {
        type: actionTypes.PRINT_MY_PROFILE_FAIL,
        error: error,
    };
};

export const printMyProfile = (endorsed) => {
    return (dispatch) => {
        dispatch(printMyProfileStart());

        return ProfileAPI.printMyPofiles(endorsed)
            .then((res) => {
                dispatch(printMyProfileSuccess(res));
            })
            .catch((err) => {
                dispatch(printMyProfileFail(err));
            });
    };
};

export const printManagedPeopleProfileStart = () => {
    return {
        type: actionTypes.PRINT_MANAGED_PEOPLE_PROFILE_START,
    };
};

export const printManagedPeopleProfileSuccess = (printData) => {
    return {
        type: actionTypes.PRINT_MANAGED_PEOPLE_PROFILE_SUCCESS,
        printData,
    };
};

export const printManagedPeopleProfileFail = (error) => {
    return {
        type: actionTypes.PRINT_MANAGED_PEOPLE_PROFILE_FAIL,
        error: error,
    };
};

export const printManagedPeopleProfile = (email, endorsed) => {
    return (dispatch) => {
        dispatch(printManagedPeopleProfileStart());

        return ProfileAPI.printManagedPeoplePofiles(email, endorsed)
            .then((res) => {
                dispatch(printManagedPeopleProfileSuccess(res));
            })
            .catch((err) => {
                dispatch(printManagedPeopleProfileFail(err));
            });
    };
};

export const emailMyProfileReportStart = () => {
    return {
        type: actionTypes.EMAIL_MY_PROFILE_REPORT_START,
    };
};

export const emailMyProfileReportSuccess = () => {
    return {
        type: actionTypes.EMAIL_MY_PROFILE_REPORT_SUCCESS,
    };
};

export const emailMyProfileReportFail = (error) => {
    return {
        type: actionTypes.EMAIL_MY_PROFILE_REPORT_FAIL,
        error: error,
    };
};

export const emailMyProfileReport = () => {
    return (dispatch) => {
        dispatch(emailMyProfileReportStart());

        return ProfileAPI.emailMyPofilesReport()
            .then((res) => {
                dispatch(emailMyProfileReportSuccess(res));
            })
            .catch((err) => {
                dispatch(emailMyProfileReportFail(err));
            });
    };
};

export const generatePersonalDataExcelStart = () => {
    return {
        type: actionTypes.GENERATE_PERSONAL_DATA_EXCEL_START,
    };
};

export const generatePersonalDataExcelSuccess = (printData) => {
    return {
        type: actionTypes.GENERATE_PERSONAL_DATA_EXCEL_SUCCESS,
        printData,
    };
};

export const generatePersonalDataExcelFail = (error) => {
    return {
        type: actionTypes.GENERATE_PERSONAL_DATA_EXCEL_FAIL,
        error: error,
    };
};

export const generatePersonalDataExcel = () => {
    return (dispatch) => {
        dispatch(generatePersonalDataExcelStart());

        return ProfileAPI.generateMyPersonalDataExcel()
            .then((res) => {
                dispatch(generatePersonalDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(generatePersonalDataExcelFail(err));
            });
    };
};

export const updateMySkillProfileStart = () => {
    return {
        type: actionTypes.UPDATE_SKILL_PROFILES_START,
    };
};

export const updateMySkillProfileSuccess = (udpatedSkills) => {
    return {
        type: actionTypes.UPDATE_SKILL_PROFILES_SUCCESS,
        udpatedSkills,
    };
};

export const updateMySkillProfileFail = (error) => {
    return {
        type: actionTypes.UPDATE_SKILL_PROFILES_FAIL,
        error: error,
    };
};

export const clearUpdateMySkillProfile = () => {
    return {
        type: actionTypes.CLEAR_UPDATE_SKILL_PROFILES,
    };
};

export const updateMySkillProfile = (updatedSkills) => {
    return (dispatch) => {
        dispatch(updateMySkillProfileStart());
        ProfileAPI.updateMyProfile(updatedSkills);
    };
};

export const updateManagedPeopleProfile = (email, updatedSkills) => {
    return (dispatch) => {
        dispatch(updateMySkillProfileStart());
        ProfileAPI.updateManagedPeopleProfile(email, updatedSkills);
    };
};

export const updateEndorsedStatusStart = () => {
    return {
        type: actionTypes.UPDATE_ENDORSED_STATUS_START,
    };
};

export const updateEndorsedStatusSuccess = (updatedPersonEndorsement) => {
    return {
        type: actionTypes.UPDATE_ENDORSED_STATUS_SUCCESS,
        updatedPersonEndorsement,
    };
};

export const updateEndorsedStatusFail = (error) => {
    return {
        type: actionTypes.UPDATE_ENDORSED_STATUS_FAIL,
        error: error,
    };
};

export const updateEndorsedStatus = (email, status) => {
    return (dispatch) => {
        dispatch(updateEndorsedStatusStart());

        return ProfileAPI.updateManagedPeopleEndorsedStatus(email, status)
            .then((res) => {
                dispatch(updateEndorsedStatusSuccess(res));
            })
            .catch((err) => {
                dispatch(updateEndorsedStatusFail(err));
            });
    };
};

export const copyEndorsedProfileStart = () => {
    return {
        type: actionTypes.COPY_ENDORSED_PROFILES_START,
    };
};

export const copyEndorsedProfileSuccess = () => {
    return {
        type: actionTypes.COPY_ENDORSED_PROFILES_SUCCESS,
    };
};

export const copyEndorsedProfileFail = (error) => {
    return {
        type: actionTypes.COPY_ENDORSED_PROFILES_FAIL,
        error: error,
    };
};

export const copyEndorsedProfile = () => {
    return (dispatch) => {
        dispatch(copyEndorsedProfileStart());

        return ProfileAPI.copyEndorsedProfile()
            .then((res) => {
                dispatch(copyEndorsedProfileSuccess(res));
            })
            .catch((err) => {
                dispatch(copyEndorsedProfileFail(err));
            });
    };
};

export const clearCopyEndorsedNotif = () => {
    return {
        type: actionTypes.CLEAR_COPY_ENDORSED_NOTIF,
    };
};

export const copyProfileStart = () => {
    return {
        type: actionTypes.COPY_PROFILE_START,
    };
};

export const copyProfileSuccess = (updatedSkills) => {
    return {
        type: actionTypes.COPY_PROFILE_SUCCESS,
        updatedSkills,
    };
};

export const copyProfileFail = (error) => {
    return {
        type: actionTypes.COPY_PROFILE_FAIL,
        error: error,
    };
};

export const copyProfile = (version, profileType) => {
    return (dispatch) => {
        dispatch(copyProfileStart());

        return ProfileAPI.copyProfile(version, profileType)
            .then((res) => {
                dispatch(copyProfileSuccess(res));
            })
            .catch((err) => {
                dispatch(copyProfileFail(err));
            });
    };
};

export const updateMySkillProfileCommentStart = () => {
    return {
        type: actionTypes.UPDATE_MY_SKILL_PROFILE_COMMENT_START,
    };
};

export const updateMySkillProfileCommentSuccess = () => {
    return {
        type: actionTypes.UPDATE_MY_SKILL_PROFILE_COMMENT_SUCCESS,
    };
};

export const updateMySkillProfileCommentFail = (error) => {
    return {
        type: actionTypes.UPDATE_MY_SKILL_PROFILE_COMMENT_FAIL,
        error: error,
    };
};

export const updateMySkillProfileComment = (id, comment) => {
    return (dispatch) => {
        dispatch(updateMySkillProfileCommentStart());

        return ProfileAPI.updateMySkillProfileComment(id, comment)
            .then((res) => {
                dispatch(updateMySkillProfileCommentSuccess(res));
            })
            .catch((err) => {
                dispatch(updateMySkillProfileCommentFail(err));
            });
    };
};

export const updateManagedPeopleSkillProfileCommentStart = () => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_SKILL_PROFILE_COMMENT_START,
    };
};

export const updateManagedPeopleSkillProfileCommentSuccess = () => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_SKILL_PROFILE_COMMENT_SUCCESS,
    };
};

export const updateManagedPeopleSkillProfileCommentFail = (error) => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_SKILL_PROFILE_COMMENT_FAIL,
        error: error,
    };
};

export const updateManagedPeopleSkillProfileComment = (id, comment, email) => {
    return (dispatch) => {
        dispatch(updateManagedPeopleSkillProfileCommentStart());

        return ProfileAPI.updateManagedPeopleSkillProfileComment(id, comment, email)
            .then((res) => {
                dispatch(updateManagedPeopleSkillProfileCommentSuccess(res));
            })
            .catch((err) => {
                dispatch(updateManagedPeopleSkillProfileCommentFail(err));
            });
    };
};

export const updateMyLorProfileCommentStart = () => {
    return {
        type: actionTypes.UPDATE_MY_LOR_PROFILE_COMMENT_START,
    };
};

export const updateMyLorProfileCommentSuccess = () => {
    return {
        type: actionTypes.UPDATE_MY_LOR_PROFILE_COMMENT_SUCCESS,
    };
};

export const updateMyLorProfileCommentFail = (error) => {
    return {
        type: actionTypes.UPDATE_MY_LOR_PROFILE_COMMENT_FAIL,
        error: error,
    };
};

export const updateMyLorProfileComment = (id, comment) => {
    return (dispatch) => {
        dispatch(updateMyLorProfileCommentStart());

        return ProfileAPI.updateMyLorProfileComment(id, comment)
            .then((res) => {
                dispatch(updateMyLorProfileCommentSuccess(res));
            })
            .catch((err) => {
                dispatch(updateMyLorProfileCommentFail(err));
            });
    };
};

export const updateManagedPeopleLorProfileCommentStart = () => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_LOR_PROFILE_COMMENT_START,
    };
};

export const updateManagedPeopleLorProfileCommentSuccess = () => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_LOR_PROFILE_COMMENT_SUCCESS,
    };
};

export const updateManagedPeopleLorProfileCommentFail = (error) => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_LOR_PROFILE_COMMENT_FAIL,
        error: error,
    };
};

export const updateManagedPeopleLorProfileComment = (id, comment, email) => {
    return (dispatch) => {
        dispatch(updateManagedPeopleLorProfileCommentStart());

        return ProfileAPI.updateManagedPeopleLorProfileComment(id, comment, email)
            .then((res) => {
                dispatch(updateManagedPeopleLorProfileCommentSuccess(res));
            })
            .catch((err) => {
                dispatch(updateManagedPeopleLorProfileCommentFail(err));
            });
    };
};
