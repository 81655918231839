import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

const initialState = {
    error: null,
    loading: false,
    isUpdating: false,
    interventions: [],
    actionPromotions: [],
    numberActiveActionPromotions: null,

    exportData: null,
    loadingExport: false,
    isImporting: false,
};

const getInterventionsStart = (state) => {
    return updateState(state, { error: null, loading: true, interventions: [] });
};

const getInterventionsSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        loading: false,
        interventions: action.interventions,
    });
};

const getInterventionsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const addInterventionStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const addInterventionSuccess = (state, action) => {
    const interventions = state.interventions;
    interventions.push(action.addedIntervention);

    return updateState(state, {
        error: null,
        isUpdating: false,
        interventions: [...interventions],
    });
};

const addInterventionFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateInterventionStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateInterventionSuccess = (state, action) => {
    const updatedIntervention = action.updatedIntervention;
    const interventions = state.interventions;

    const intervention = interventions.find((it) => it.id == updatedIntervention.id);
    if (intervention) {
        intervention.companyId = updatedIntervention.companyId;
        intervention.company = updatedIntervention.company;
        intervention.actionType = updatedIntervention.actionType;
        intervention.name = updatedIntervention.name;
        intervention.description = updatedIntervention.description;
        intervention.isActive = updatedIntervention.isActive;
        intervention.duration = updatedIntervention.duration;
        intervention.durationHours = updatedIntervention.durationHours;
        intervention.link = updatedIntervention.link;
        intervention.versionNumber = updatedIntervention.versionNumber;
        intervention.publishedOn = updatedIntervention.publishedOn;
        intervention.retireOn = updatedIntervention.retireOn;
        intervention.reviewOn = updatedIntervention.reviewOn;
        intervention.isValidated = updatedIntervention.isValidated;
        intervention.sfiaVersion = updatedIntervention.sfiaVersion;
        intervention.levels = updatedIntervention.levels;
        intervention.lorLevels = updatedIntervention.lorLevels;
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        interventions: [...interventions],
    });
};

const updateInterventionFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteInterventionStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteInterventionSuccess = (state, action) => {
    const deletedInterventionId = action.deletedInterventionId;
    const interventions = state.interventions;

    const intervention = interventions.find((it) => it.id == deletedInterventionId);
    if (intervention) {
        const index = interventions.indexOf(intervention);
        if (index !== -1) {
            interventions.splice(index, 1);
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        interventions: [...interventions],
    });
};

const deleteInterventionFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteInterventionsStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteInterventionsSuccess = (state, action) => {
    const deletedInterventionIds = action.deletedInterventionIds;
    const interventions = state.interventions;

    deletedInterventionIds.map((deletedInterventionId) => {
        const intervention = interventions.find((it) => it.id == deletedInterventionId);
        if (intervention) {
            const index = interventions.indexOf(intervention);
            if (index !== -1) {
                interventions.splice(index, 1);
            }
        }
    });

    return updateState(state, {
        error: null,
        isUpdating: false,
        interventions: [...interventions],
    });
};

const deleteInterventionsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const exportInterventionsDataExcelStart = (state) => {
    return updateState(state, { error: null, loadingExport: true });
};

const exportInterventionsDataExcelSuccess = (state, action) => {
    return updateState(state, {
        exportData: action.exportData,
        error: null,
        loadingExport: false,
    });
};

const exportInterventionsDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingExport: false,
    });
};

const importInterventionsDataExcelStart = (state) => {
    return updateState(state, {
        error: null,
        isImporting: true,
        //addedJobImports: []
    });
};

const importInterventionsDataExcelSuccess = (state, action) => {
    // let jobImportQueue = state.jobImportQueue;
    // const addedJobImportQueue = action.addedJobImportQueue;

    return updateState(state, {
        error: null,
        isImporting: false,
        //addedJobImports: addedJobImportQueue,
    });
};

const importInterventionsDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isImporting: false,
    });
};

const assignInterventionJobStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const assignInterventionJobSuccess = (state, action) => {
    const addedInterventionJob = action.addedInterventionJob;
    const interventions = state.interventions;

    const intervention = interventions.find((it) => it.id == addedInterventionJob.interventionId);
    if (intervention) {
        intervention.jobs.push(addedInterventionJob);
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        interventions: [...interventions],
    });
};

const assignInterventionJobFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteInterventionJobStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteInterventionJobSuccess = (state, action) => {
    const deletedInterventionJobId = action.deletedInterventionJobId;
    const interventions = state.interventions;

    const intervention = interventions.find((it) => it.jobs.find((it) => it.id == deletedInterventionJobId));
    const interventionJob = intervention.jobs.find((it) => it.id == deletedInterventionJobId);
    if (interventionJob) {
        const index = intervention.jobs.indexOf(interventionJob);
        if (index !== -1) {
            intervention.jobs.splice(index, 1);
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        interventions: [...interventions],
    });
};

const deleteInterventionJobFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteInterventionJobsStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteInterventionJobsSuccess = (state, action) => {
    const deletedInterventionJobIds = action.deletedInterventionJobIds;
    const interventions = state.interventions;

    deletedInterventionJobIds.forEach((deletedInterventionJobId) => {
        const intervention = interventions.find((it) => it.jobs.find((it) => it.id == deletedInterventionJobId));
        const interventionJob = intervention.jobs.find((it) => it.id == deletedInterventionJobId);
        if (interventionJob) {
            const index = intervention.jobs.indexOf(interventionJob);
            if (index !== -1) {
                intervention.jobs.splice(index, 1);
            }
        }
    });

    return updateState(state, {
        error: null,
        isUpdating: false,
        interventions: [...interventions],
    });
};

const deleteInterventionJobsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const getNumberActiveActionPromotionsStart = (state) => {
    return updateState(state, { error: null, numberActiveActionPromotions: null });
};

const getNumberActiveActionPromotionsSuccess = (state, action) => {
    return updateState(state, {
        numberActiveActionPromotions: action.numberActiveActionPromotions,
        error: null,
    });
};

const getNumberActiveActionPromotionsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
    });
};

const getActionPromotionsStart = (state) => {
    return updateState(state, { error: null, actionPromotions: [], loading: true });
};

const getActionPromotionsSuccess = (state, action) => {
    return updateState(state, {
        actionPromotions: action.actionPromotions,
        error: null,
        loading: false,
    });
};

const getActionPromotionsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updateActionPromotionStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateActionPromotionSuccess = (state, action) => {
    const updatedActionPromotion = action.updatedActionPromotion;
    const actionPromotions = state.actionPromotions;

    const actionPromotion = actionPromotions.find((it) => it.id == updatedActionPromotion.id);
    if (actionPromotion) {
        actionPromotion.status = updatedActionPromotion.status;
        actionPromotion.actionType = updatedActionPromotion.actionType;
        actionPromotion.name = updatedActionPromotion.name;
        actionPromotion.description = updatedActionPromotion.description;
        actionPromotion.isActive = updatedActionPromotion.isActive;
        actionPromotion.duration = updatedActionPromotion.duration;
        actionPromotion.durationHours = updatedActionPromotion.durationHours;
        actionPromotion.link = updatedActionPromotion.link;
        actionPromotion.levels = updatedActionPromotion.levels;
        actionPromotion.lorLevels = updatedActionPromotion.lorLevels;
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        actionPromotions: [...actionPromotions],
    });
};

const updateActionPromotionFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INTERVENTIONS_START:
            return getInterventionsStart(state);
        case actionTypes.GET_INTERVENTIONS_SUCCESS:
            return getInterventionsSuccess(state, action);
        case actionTypes.GET_INTERVENTIONS_FAIL:
            return getInterventionsFail(state, action);

        case actionTypes.ADD_INTERVENTION_START:
            return addInterventionStart(state);
        case actionTypes.ADD_INTERVENTION_SUCCESS:
            return addInterventionSuccess(state, action);
        case actionTypes.ADD_INTERVENTION_FAIL:
            return addInterventionFail(state, action);

        case actionTypes.UPDATE_INTERVENTION_START:
            return updateInterventionStart(state);
        case actionTypes.UPDATE_INTERVENTION_SUCCESS:
            return updateInterventionSuccess(state, action);
        case actionTypes.UPDATE_INTERVENTION_FAIL:
            return updateInterventionFail(state, action);

        case actionTypes.DELETE_INTERVENTION_START:
            return deleteInterventionStart(state);
        case actionTypes.DELETE_INTERVENTION_SUCCESS:
            return deleteInterventionSuccess(state, action);
        case actionTypes.DELETE_INTERVENTION_FAIL:
            return deleteInterventionFail(state, action);

        case actionTypes.DELETE_INTERVENTIONS_START:
            return deleteInterventionsStart(state);
        case actionTypes.DELETE_INTERVENTIONS_SUCCESS:
            return deleteInterventionsSuccess(state, action);
        case actionTypes.DELETE_INTERVENTIONS_FAIL:
            return deleteInterventionsFail(state, action);

        case actionTypes.EXPORT_INTERVENTIONS_DATA_START:
            return exportInterventionsDataExcelStart(state);
        case actionTypes.EXPORT_INTERVENTIONS_DATA_SUCCESS:
            return exportInterventionsDataExcelSuccess(state, action);
        case actionTypes.EXPORT_INTERVENTIONS_DATA_FAIL:
            return exportInterventionsDataExcelFail(state, action);

        case actionTypes.IMPORT_INTERVENTIONS_DATA_START:
            return importInterventionsDataExcelStart(state);
        case actionTypes.IMPORT_INTERVENTIONS_DATA_SUCCESS:
            return importInterventionsDataExcelSuccess(state, action);
        case actionTypes.IMPORT_INTERVENTIONS_DATA_FAIL:
            return importInterventionsDataExcelFail(state, action);

        case actionTypes.ASSIGN_INTERVENTION_JOB_START:
            return assignInterventionJobStart(state);
        case actionTypes.ASSIGN_INTERVENTION_JOB_SUCCESS:
            return assignInterventionJobSuccess(state, action);
        case actionTypes.ASSIGN_INTERVENTION_JOB_FAIL:
            return assignInterventionJobFail(state, action);

        case actionTypes.DELETE_INTERVENTION_JOB_START:
            return deleteInterventionJobStart(state);
        case actionTypes.DELETE_INTERVENTION_JOB_SUCCESS:
            return deleteInterventionJobSuccess(state, action);
        case actionTypes.DELETE_INTERVENTION_JOB_FAIL:
            return deleteInterventionJobFail(state, action);

        case actionTypes.DELETE_INTERVENTION_JOBS_START:
            return deleteInterventionJobsStart(state);
        case actionTypes.DELETE_INTERVENTION_JOBS_SUCCESS:
            return deleteInterventionJobsSuccess(state, action);
        case actionTypes.DELETE_INTERVENTION_JOBS_FAIL:
            return deleteInterventionJobsFail(state, action);

        case actionTypes.GET_NO_OF_ACTIVE_ACTION_PROMOTIONS_START:
            return getNumberActiveActionPromotionsStart(state);
        case actionTypes.GET_NO_OF_ACTIVE_ACTION_PROMOTIONS_SUCCESS:
            return getNumberActiveActionPromotionsSuccess(state, action);
        case actionTypes.GET_NO_OF_ACTIVE_ACTION_PROMOTIONS_FAIL:
            return getNumberActiveActionPromotionsFail(state, action);

        case actionTypes.GET_ACTION_PROMOTIONS_START:
            return getActionPromotionsStart(state);
        case actionTypes.GET_ACTION_PROMOTIONS_SUCCESS:
            return getActionPromotionsSuccess(state, action);
        case actionTypes.GET_ACTION_PROMOTIONS_FAIL:
            return getActionPromotionsFail(state, action);

        case actionTypes.UPDATE_ACTION_PROMOTION_START:
        case actionTypes.ACCEPT_ACTION_PROMOTION_START:
        case actionTypes.DECLINE_ACTION_PROMOTION_START:
            return updateActionPromotionStart(state);
        case actionTypes.UPDATE_ACTION_PROMOTION_SUCCESS:
        case actionTypes.ACCEPT_ACTION_PROMOTION_SUCCESS:
        case actionTypes.DECLINE_ACTION_PROMOTION_SUCCESS:
            return updateActionPromotionSuccess(state, action);
        case actionTypes.UPDATE_ACTION_PROMOTION_FAIL:
        case actionTypes.ACCEPT_ACTION_PROMOTION_FAIL:
        case actionTypes.DECLINE_ACTION_PROMOTION_FAIL:
            return updateActionPromotionFail(state, action);

        default:
            return state;
    }
};

export default reducer;
