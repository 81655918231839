import * as lorActions from "../../store/actions/lorAction";
import ContentTranslator from "../translator/ContentTranslator";
import ActionPlanResponsibilityBody from "./TableActionPlanResponsibilityBody";
import React from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const lorOrders = [
    { lor: "Autonomy", order: 1 },
    { lor: "Influence", order: 2 },
    { lor: "Complexity", order: 3 },
    { lor: "Business Skills", order: 4 },
    { lor: "Knowledge", order: 5 },
    { lor: "Responsibility and Autonomy", order: 1 },
    { lor: "Complexity and Scope", order: 2 },
    { lor: "Skills", order: 3 },
];

class ActionPlanResponsibility extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showActionForm: false,
        };

        this.initData = this.initData.bind(this);
    }

    componentDidMount() {
        this.initData();
    }

    initData() {}

    TableBodyResponsibility = (category) => {
        const { lors, actions, jobGaps, lorProfiles, skillProfiles, jobLorProfiles, jobSkillProfiles } = this.props;

        if (lors) {
            lors.forEach((lor) => {
                const lorOrder = lorOrders.find((it) => it.lor == lor.name);
                if (lorOrder) {
                    lor.order = lorOrder.order;
                } else {
                    lor.order = 999;
                }
            });
        }

        if (this.state.loading) {
            return (
                <tr>
                    <td colSpan="9">
                        <div style={{ textAlign: "center" }}>
                            <Spinner animation="border" role="status" style={{ marginLeft: "20px" }}></Spinner>
                        </div>
                    </td>
                </tr>
            );
        } else {
            const mainRows = lors
                .filter((it) => (it.category ? it.category : "Generic Attributes") == (category ? category : "Generic Attributes"))
                .sort((a, b) => (`${a.order}${a.name}` > `${b.order}${b.name}` ? 1 : -1))
                .map((lor, index) => {
                    const lorProfile = lorProfiles.find((x) => x.lor === lor.name);
                    const action = actions.find((action) => action.skills.some((skill) => skill.code == lor.lorCode));

                    return (
                        <ActionPlanResponsibilityBody
                            key={index}
                            index={index}
                            showAll={this.props.showAll}
                            showHiddenSkills={this.props.showHiddenSkills}
                            selectedJob={this.props.selectedJob}
                            currentJob={this.props.currentJob}
                            futureJob={this.props.futureJob}
                            lor={lor}
                            categories={this.props.categories}
                            skillProfile={lorProfile}
                            lorProfiles={lorProfiles}
                            skillProfiles={skillProfiles}
                            jobLorProfiles={jobLorProfiles}
                            jobSkillProfiles={jobSkillProfiles}
                            action={action}
                            optionsActionTypes={this.props.optionsActionTypes}
                            optionsActionSkills={this.props.optionsActionSkills}
                            jobGaps={jobGaps}
                            handleActionPlanAdded={this.props.handleActionPlanAdded}
                            providers={this.props.providers}
                            allowUpdate={this.props.allowUpdate}
                            optionsJob={this.props.optionsJob}
                        ></ActionPlanResponsibilityBody>
                    );
                });

            return mainRows;
        }
    };

    render() {
        const { lors, lorProfiles, jobLorProfiles, selectedJob } = this.props;

        const lorCategories = [];

        if (lors.find((it) => !it.category)) {
            lorCategories.push({
                category: "Generic Attributes",
                categoryColor: "#69849c",
                lorColor: "#ecf0f5",
            });
        }

        lors.filter((it) => it.category).forEach((lor) => {
            if (!lorCategories.find((it) => it.category == lor.category)) {
                lorCategories.push({
                    category: lor.category,
                    categoryColor: lor.categoryColor,
                    lorColor: lor.lorColor,
                });
            }
        });

        let showLors = false;

        lors.map((lor) => {
            const lorProfile = lorProfiles.find((x) => x.lor === lor.name);
            const selectedJobLorProfile = selectedJob && jobLorProfiles.find((it) => it.lor === lor.name && it.jobId == selectedJob.id);

            if ((selectedJobLorProfile && lorProfile && lorProfile.numericVal < selectedJobLorProfile.numericVal) || this.props.showAll) {
                showLors = true;
            }
        });

        return (
            <React.Fragment>
                {showLors &&
                    lorCategories.map((lorCategory, index) => {
                        let showCategoryLors = false;

                        lors.filter((it) => (it.category ? it.category : "Generic Attributes") == (lorCategory.category ? lorCategory.category : "Generic Attributes")).map((lor) => {
                            const lorProfile = lorProfiles.find((x) => x.lor === lor.name);
                            const selectedJobLorProfile = selectedJob && jobLorProfiles.find((it) => it.lor === lor.name && it.jobId == selectedJob.id);

                            if ((selectedJobLorProfile && lorProfile && lorProfile.numericVal < selectedJobLorProfile.numericVal) || this.props.showAll) {
                                showCategoryLors = true;
                            }
                        });

                        return (
                            <React.Fragment key={index}>
                                {showCategoryLors && (
                                    <React.Fragment>
                                        <thead className="tableHeader">
                                            <tr>
                                                <th className={"th-start"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                                    {lorCategory.category == "Generic Attributes" ? <ContentTranslator page="SfiaPlan" name="SfiaPlanLorProfileTitle" contentText="Generic Attributes" /> : lorCategory.category}
                                                </th>
                                                <th className={"th-fixwidth-120 text-center"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                                    <ContentTranslator page="SfiaAll" name={this.props.selectedOptionProfile} contentText={this.props.selectedOptionProfile.replace(" ", "")} />
                                                </th>
                                                <th className={"th-fixwidth-120 text-center"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                                    <ContentTranslator page="SfiaAll" name="Current" contentText="Current" />
                                                </th>
                                                <th className={"th-fixwidth-120 text-center"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                                    <ContentTranslator page="SfiaAll" name="Future" contentText="Future" />
                                                </th>
                                                <th className={"th-fixwidth text-center th-end"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanAddAction" contentText="Add Action" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>{this.TableBodyResponsibility(lorCategory.category)}</tbody>
                                        <tbody className="border-0">
                                            <tr>
                                                <td className="border-0"></td>
                                            </tr>
                                        </tbody>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        );
                    })}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lors: state.lor.lors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLor: bindActionCreators(lorActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionPlanResponsibility);
