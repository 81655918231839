import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class ActionPlanAPI {
    static getActionTypes() {
        return axios
            .get(`/actionplans/actionTypes`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getActionSkills() {
        return axios
            .get(`/actionplans/actionSkills`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getFilteredInterventions(filter) {
        return axios
            .post(`/actionplans/interventions/filter`, filter)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateHideIntervetion(id) {
        return axios
            .post(`/actionplans/interventions/${id}/hide`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateUnHideIntervetion(id) {
        return axios
            .post(`/actionplans/interventions/${id}/unhide`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getActions() {
        return axios
            .get(`/actionplans/myactions`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getManagedPeopleActions(email) {
        return axios
            .get(`/actionplans/managedPeopleActions?email=${email}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createAction(action) {
        return axios
            .post("/actionplans/myactions", action)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateAction(id, action) {
        return axios
            .put(`/actionplans/myactions/${id}`, action)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateHideAction(id) {
        return axios
            .post(`/actionplans/myactions/${id}/hide`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateUnHideAction(id) {
        return axios
            .post(`/actionplans/myactions/${id}/unhide`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteAction(id) {
        return axios
            .delete(`/actionplans/myactions/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static printActionReport() {
        return axios
            .get(`/actionplans/printreport`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static createManagedPeopleAction(action) {
        return axios
            .post("/actionplans/managedPeopleActions", action)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateManagedPeopleAction(id, action) {
        return axios
            .put(`/actionplans/managedPeopleActions/${id}`, action)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteManagedPeopleAction(id, email) {
        return axios
            .delete(`/actionplans/managedPeopleActions/${id}?email=${email}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static printManagedPeopleActionReport(email) {
        return axios
            .get(`/actionplans/printManagedPeopleReport?email=${email}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static sendUpdatedActionPlanNotification(email) {
        return axios
            .post(`/actionplans/sendUpdatedActionPlanNotification?email=${email}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static submitActionPromotion(actionId, description) {
        return axios
            .post(`/actionplans/promoteaction/${actionId}`, { description })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static submitProposeAction(actionId, personEmail, updateProfileEvidenceProposalSkills) {
        return axios
            .post(`/actionplans/managedPeopleActions/${actionId}/proposeupdateprofileevidence`, { personEmail, updateProfileEvidenceProposalSkills })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static acceptUpdateProfileFromActionProposal(actionId, comment, timezoneOffset) {
        return axios
            .post(`/actionplans/myactions/${actionId}/acceptproposal`, { comment, timezoneOffset })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static rejectUpdateProfileFromActionProposal(actionId, comment, timezoneOffset) {
        return axios
            .post(`/actionplans/myactions/${actionId}/rejectproposal`, { comment, timezoneOffset })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default ActionPlanAPI;
