import ConfigAPI from "./../../common/api/service/ConfigService";
import * as actionTypes from "./actionTypes";

export const getSamlEnabledStatusStart = () => {
    return {
        type: actionTypes.GET_CONFIG_SAML_ENABLED_STATUS_START,
    };
};

export const getSamlEnabledStatusSuccess = (samlEnabled, restrictLoginSSOOnly, byPassLoginPage) => {
    return {
        type: actionTypes.GET_CONFIG_SAML_ENABLED_STATUS_SUCCESS,
        samlEnabled,
        restrictLoginSSOOnly,
        byPassLoginPage,
    };
};

export const getSamlEnabledStatusFail = (error) => {
    return {
        type: actionTypes.GET_CONFIG_SAML_ENABLED_STATUS_FAIL,
        error: error,
    };
};

export const getSamlEnabledStatus = () => {
    return (dispatch) => {
        dispatch(getSamlEnabledStatusStart());

        return ConfigAPI.getConfigSamlEnabledStatus()
            .then((res) => {
                dispatch(getSamlEnabledStatusSuccess(res.samlEnabled, res.restrictLoginSSOOnly, res.byPassLoginPage));
            })
            .catch((err) => {
                dispatch(getSamlEnabledStatusFail(err));
            });
    };
};

export const clearSAMLEnabledStatus = () => {
    return {
        type: actionTypes.CLEAR_SAML_ENABLED_STATUS,
    };
};

export const getCurrAssessmentStart = () => {
    return {
        type: actionTypes.GET_CONFIG_CURR_ASSESSMENT_START,
    };
};

export const getCurrAssessmentSuccess = (currAssessment) => {
    return {
        type: actionTypes.GET_CONFIG_CURR_ASSESSMENT_SUCCESS,
        currAssessment,
    };
};

export const getCurrAssessmentFail = (error) => {
    return {
        type: actionTypes.GET_CONFIG_CURR_ASSESSMENT_FAIL,
        error: error,
    };
};

export const getCurrAssessment = () => {
    return (dispatch) => {
        dispatch(getCurrAssessmentStart());

        return ConfigAPI.getConfigCurrAssessment()
            .then((res) => {
                dispatch(getCurrAssessmentSuccess(res));
            })
            .catch((err) => {
                dispatch(getCurrAssessmentFail(err));
            });
    };
};

export const clearCurrAssessment = () => {
    return {
        type: actionTypes.CLEAR_CONFIG_CURR_ASSESSMENT,
    };
};

export const getContainerConfigStart = () => {
    return {
        type: actionTypes.GET_CONTAINER_CONFIG_START,
    };
};

export const getContainerConfigSuccess = (containerConfig) => {
    return {
        type: actionTypes.GET_CONTAINER_CONFIG_SUCCESS,
        containerConfig,
    };
};

export const getContainerConfigFail = (error) => {
    return {
        type: actionTypes.GET_CONTAINER_CONFIG_FAIL,
        error: error,
    };
};

export const getContainerConfig = () => {
    return (dispatch) => {
        dispatch(getContainerConfigStart());

        return ConfigAPI.getContainerConfig()
            .then((res) => {
                dispatch(getContainerConfigSuccess(res));
            })
            .catch((err) => {
                dispatch(getContainerConfigFail(err));
            });
    };
};

export const getAdminConfigStart = () => {
    return {
        type: actionTypes.GET_ADMIN_CONFIG_START,
    };
};

export const getAdminConfigSuccess = (config) => {
    return {
        type: actionTypes.GET_ADMIN_CONFIG_SUCCESS,
        config,
    };
};

export const getAdminConfigFail = (error) => {
    return {
        type: actionTypes.GET_ADMIN_CONFIG_FAIL,
        error: error,
    };
};

export const getAdminConfig = () => {
    return (dispatch) => {
        dispatch(getAdminConfigStart());

        return ConfigAPI.getAdminConfig()
            .then((res) => {
                dispatch(getAdminConfigSuccess(res));
            })
            .catch((err) => {
                dispatch(getAdminConfigFail(err));
            });
    };
};

export const updateAdminConfigStart = () => {
    return {
        type: actionTypes.UPDATE_ADMIN_CONFIG_START,
    };
};

export const updateAdminConfigSuccess = (updatedConfig) => {
    return {
        type: actionTypes.UPDATE_ADMIN_CONFIG_SUCCESS,
        updatedConfig,
    };
};

export const updateAdminConfigFail = (error) => {
    return {
        type: actionTypes.UPDATE_ADMIN_CONFIG_FAIL,
        error: error,
    };
};

export const updateAdminConfig = (config) => {
    return (dispatch) => {
        dispatch(updateAdminConfigStart());

        return ConfigAPI.updateAdminConfig(config)
            .then((res) => {
                dispatch(updateAdminConfigSuccess(res));
            })
            .catch((err) => {
                dispatch(updateAdminConfigFail(err));
            });
    };
};

export const updateAdminPersonMiscellaneousConfig = (config) => {
    return (dispatch) => {
        dispatch(updateAdminConfigStart());

        return ConfigAPI.updateAdminPersonMiscellaneousConfig(config)
            .then((res) => {
                dispatch(updateAdminConfigSuccess(res));
            })
            .catch((err) => {
                dispatch(updateAdminConfigFail(err));
            });
    };
};

export const downloadPowerBIModelStart = () => {
    return {
        type: actionTypes.DOWNLOAD_POWERBIMODEL_START,
    };
};

export const downloadPowerBIModelSuccess = (blobData) => {
    return {
        type: actionTypes.DOWNLOAD_POWERBIMODEL_SUCCESS,
        blobData,
    };
};

export const downloadPowerBIModelFail = (error) => {
    return {
        type: actionTypes.DOWNLOAD_POWERBIMODEL_FAIL,
        error: error,
    };
};

export const downloadPowerBIModel = () => {
    return (dispatch) => {
        dispatch(downloadPowerBIModelStart());

        return ConfigAPI.downloadPowerBIModel()
            .then((res) => {
                dispatch(downloadPowerBIModelSuccess(res));
            })
            .catch((err) => {
                dispatch(downloadPowerBIModelFail(err));
            });
    };
};

export const getContainerAppSettingStart = () => {
    return {
        type: actionTypes.GET_CONTAINER_APP_SETTING_START,
    };
};

export const getContainerAppSettingSuccess = (containerAppSetting) => {
    return {
        type: actionTypes.GET_CONTAINER_APP_SETTING_SUCCESS,
        containerAppSetting,
    };
};

export const getContainerAppSettingFail = (error) => {
    return {
        type: actionTypes.GET_CONTAINER_APP_SETTING_FAIL,
        error: error,
    };
};

export const getContainerAppSetting = () => {
    return (dispatch) => {
        dispatch(getContainerAppSettingStart());

        return ConfigAPI.getContainerAppSetting()
            .then((res) => {
                dispatch(getContainerAppSettingSuccess(res));
            })
            .catch((err) => {
                dispatch(getContainerAppSettingFail(err));
            });
    };
};

export const updateContainerAppSettingStart = () => {
    return {
        type: actionTypes.UPDATE_CONTAINER_APP_SETTING_START,
    };
};

export const updateContainerAppSettingSuccess = (containerAppSetting) => {
    return {
        type: actionTypes.UPDATE_CONTAINER_APP_SETTING_SUCCESS,
        containerAppSetting,
    };
};

export const updateContainerAppSettingFail = (error) => {
    return {
        type: actionTypes.UPDATE_CONTAINER_APP_SETTING_FAIL,
        error: error,
    };
};

export const updateContainerAppSetting = (appSetting) => {
    return (dispatch) => {
        dispatch(updateContainerAppSettingStart());

        return ConfigAPI.updateContainerAppSetting(appSetting)
            .then((res) => {
                dispatch(updateContainerAppSettingSuccess(res));
            })
            .catch((err) => {
                dispatch(updateContainerAppSettingFail(err));
            });
    };
};

export const getActiveUserCountStatusStart = () => {
    return {
        type: actionTypes.GET_ACTIVE_USER_COUNT_STATUS_START,
    };
};

export const getActiveUserCountStatusSuccess = (activeUserCount, showActiveUserCountNotification) => {
    return {
        type: actionTypes.GET_ACTIVE_USER_COUNT_STATUS_SUCCESS,
        activeUserCount,
        showActiveUserCountNotification,
    };
};

export const getActiveUserCountStatusFail = (error) => {
    return {
        type: actionTypes.GET_ACTIVE_USER_COUNT_STATUS_FAIL,
        error: error,
    };
};

export const getActiveUserCountStatus = () => {
    return (dispatch) => {
        dispatch(getActiveUserCountStatusStart());

        return ConfigAPI.getActiveUserCountStatus()
            .then((res) => {
                dispatch(getActiveUserCountStatusSuccess(res.activeUserCount, res.showNotification));
            })
            .catch((err) => {
                dispatch(getActiveUserCountStatusFail(err));
            });
    };
};

export const clearActiveUserCountStatusState = () => {
    return {
        type: actionTypes.CLEAR_ACTIVE_USER_COUNT_STATUS,
    };
};

export const executeInactivationUsersLessLoginStart = () => {
    return {
        type: actionTypes.EXECUTE_INACTIVATION_USERS_LESS_LOGIN_START,
    };
};

export const executeInactivationUsersLessLoginSuccess = (numberOfPersonsPutToInactive) => {
    return {
        type: actionTypes.EXECUTE_INACTIVATION_USERS_LESS_LOGIN_SUCCESS,
        numberOfPersonsPutToInactive,
    };
};

export const executeInactivationUsersLessLoginFail = (error) => {
    return {
        type: actionTypes.EXECUTE_INACTIVATION_USERS_LESS_LOGIN_FAIL,
        error: error,
    };
};

export const executeInactivationUsersLessLogin = () => {
    return (dispatch) => {
        dispatch(executeInactivationUsersLessLoginStart());

        return ConfigAPI.inactivationUsersLessLogin()
            .then((res) => {
                dispatch(executeInactivationUsersLessLoginSuccess(res.numberOfPersonsPutToInactive));
            })
            .catch((err) => {
                dispatch(executeInactivationUsersLessLoginFail(err));
            });
    };
};
