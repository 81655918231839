import * as authActions from "../store/actions/authAction";
import * as configActions from "../store/actions/configAction";
import * as contentActions from "../store/actions/contentAction";
import * as adminPersonActions from "../store/admin/actions/adminPersonActions";
import { dismisAlert, generateAlert } from "../utils/alertUtils";
import changeInput from "../utils/changeInput";
import { Layout } from "./Layout";
import { ContentWrapper } from "./common/ContentWrapper";
import ContentTranslator from "./translator/ContentTranslator";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";
import { Userpilot } from "userpilot";

const titleStyle = {
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "38px",
    align: "left",
};

const descStyle = {
    fontFamily: "IBMPlexSans-Light",
    fontSize: "14px",
    align: "left",
};

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const userPilotToken = process.env.REACT_APP_USER_PILOT_TOKEN;

Userpilot.initialize(userPilotToken);

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loadingInitial: true,
            loading: false,
            alerts: [],
            form: {
                email: {
                    validation: {
                        required: true,
                        isEmail: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                password: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
            },
            isFormValid: false,
            boolLoginMembee: this.props.boolLoginMembee,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
    }

    componentDidMount() {
        localStorage.setItem("showApprovedProfileOnly", "FALSE");
        localStorage.setItem("manageProfileByAdminPopup", "FALSE");

        const redirectUrl = this.props.location && this.props.location.state && this.props.location.state.redirectUrl ? this.props.location.state.redirectUrl : null;
        const isLogout = this.props.location && this.props.location.state && this.props.location.state.isLogout ? this.props.location.state.isLogout : false;
        this.props.onAuth.setRedirectUrl(redirectUrl, isLogout);

        const {
            match: { params },
        } = this.props;

        var token = params.token;

        if (!token) {
            this.setState({ loadingInitial: false });

            const boolLoginFail = this.props.boolLoginFail;
            if (boolLoginFail) {
                const query = new URLSearchParams(this.props.location.search);
                var email = query.get("email");

                this.generateAlert("danger", `No account found for ${email}`);
            }

            if (this.props.boolLoginMembee) {
                window.loginMembee();
            }

            if (this.props.containerConfig && this.props.containerConfig.restrictLoginSSOOnly && this.props.containerConfig.byPassLoginPage) {
                window.location = "/sso/login";
            }
        } else {
            this.submitLoginWithToken(token);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.boolLoginMembee !== this.props.boolLoginMembee) {
            this.setState({ boolLoginMembee: this.props.boolLoginMembee });
            if (this.props.boolLoginMembee) {
                window.loginMembee();
            }
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    submitLoginWithToken = (token) => {
        this.props.onAuth.signInUseOneTimeLoginToken(token).then(() => {
            if (!this.props.loading) {
                if (this.props.error) {
                    this.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.props.connectSignalR();

                    Userpilot.identify(this.props.loginResult.personId, { name: `${this.props.loginResult.firstName} ${this.props.loginResult.lastName}`, email: this.props.loginResult.email });
                    Promise.resolve(
                        this.props.onAuth.signInSuccess(
                            this.props.loginResult.token,
                            this.props.email,
                            this.props.assessmentId,
                            this.props.jobProfileReportFormat,
                            this.props.selfAssessedProfileReportFormat,
                            this.props.hasPeoples,
                            this.props.isEndorser,
                            this.props.authMode,
                            this.props.loginResult.personId,
                            this.props.ownJobs,
                            this.props.manageEndorsements,
                            this.props.manageCertifications,
                            this.props.loginWithMembee,
                            this.props.loginWithTeams,
                            this.props.receiveLicenseNotification,
                            this.props.lastActiveUserNotification,
                            this.props.allowSkipSurvey,
                            this.props.manageSelfAssessed,
                            this.props.manageSelfProfiles,
                            this.props.hasRelationships
                        )
                    ).then(() => {
                        localStorage.setItem("manageEndorsementOperation", "HIDE");

                        let authMode = this.props.authMode;
                        let authModes = authMode.split(",").map((item) => item.trim());

                        if (authModes.includes("Survey")) {
                            this.props.history.push(`/survey/assessment/${this.props.assessmentId}`);
                        }

                        if (authModes.includes("Admin")) {
                            this.props.onAdminPerson.getPageAssignments().then(() => {});
                            this.props.onAdminPerson.getColumnDisplaySettings().then(() => {});
                        }

                        this.props.onConfig.getContainerConfig().then(() => {});
                    });
                }
            }
            this.setState({ loading: this.props.loading, loadingInitial: this.props.loading });
        });
    };

    submitForm = (event) => {
        event.preventDefault();
        this.setState({ error: null, loading: true });
        const email = this.state.form.email.value;
        const password = this.state.form.password.value;

        this.props.onAuth.signIn(email, password).then(() => {
            if (!this.props.loading) {
                if (this.props.error) {
                    this.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.props.connectSignalR();

                    Userpilot.identify(this.props.loginResult.personId, { name: `${this.props.loginResult.firstName} ${this.props.loginResult.lastName}`, email: this.props.loginResult.email });
                    this.props.onAuth.signInSuccess(
                        this.props.loginResult.token,
                        email,
                        this.props.assessmentId,
                        this.props.jobProfileReportFormat,
                        this.props.selfAssessedProfileReportFormat,
                        this.props.hasPeoples,
                        this.props.isEndorser,
                        this.props.authMode,
                        this.props.loginResult.personId,
                        this.props.ownJobs,
                        this.props.manageEndorsements,
                        this.props.manageCertifications,
                        this.props.loginWithMembee,
                        this.props.loginWithTeams,
                        this.props.receiveLicenseNotification,
                        this.props.lastActiveUserNotification,
                        this.props.allowSkipSurvey,
                        this.props.manageSelfAssessed,
                        this.props.manageSelfProfiles,
                        this.props.hasRelationships
                    );

                    localStorage.setItem("manageEndorsementOperation", "HIDE");

                    let authMode = this.props.authMode;
                    authMode = authMode ? authMode : "Planner";

                    let authModes = authMode.split(",").map((item) => item.trim());
                    if (authModes.includes("Admin")) {
                        this.props.onAdminPerson.getPageAssignments().then(() => {});
                        this.props.onAdminPerson.getColumnDisplaySettings().then(() => {});
                    }

                    this.props.onConfig.getContainerConfig().then(() => {});
                }
            }
            this.setState({ loading: this.props.loading });
        });
    };

    loginMembee = () => {
        window.loginMembee();
    };

    render() {
        const { form } = this.state;

        return (
            <Layout language={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage} boolLoginMembee={this.props.boolLoginMembee}>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loadingInitial ? (
                    <ContentWrapper>
                        <Row>
                            <Col>
                                <div className="p-col-12" style={{ textAlign: "center" }}>
                                    <Spinner color="dark" />
                                </div>
                            </Col>
                        </Row>
                    </ContentWrapper>
                ) : (
                    <React.Fragment>
                        <div id="DivLogin">
                            <ContentWrapper>
                                <Row xs="1" md="2">
                                    <Col>
                                        <p className="color-dark" style={titleStyle}>
                                            <ContentTranslator page="Login" name="Welcome" contentText="Welcome" />
                                        </p>
                                        <p className="color-dark" style={descStyle}>
                                            <ContentTranslator page="Login" name="LoginPageTitle" contentText="SkillsTx login page" />
                                        </p>
                                    </Col>
                                    <Col>
                                        <Form onSubmit={this.submitForm}>
                                            {!(this.props.samlEnabled && this.props.samlEnabled == "TRUE" && this.props.restrictLoginSSOOnly && this.props.restrictLoginSSOOnly == "TRUE") && (
                                                <React.Fragment>
                                                    <FormGroup>
                                                        <Label className="font-weight-bold" for="lblEmail">
                                                            <ContentTranslator page="Login" name="LoginEmail" contentText="Email" />
                                                        </Label>
                                                        <Input type="email" name="email" id="inputEmail" value={form.email.value} invalid={form.email.touched && (!form.email.valid || !form.email.isValidFormat)} onChange={this.handleChange} />
                                                        {form.email.value != "" && !form.email.isValidFormat && (
                                                            <FormFeedback>
                                                                <ContentTranslator page="Login" name="LoginIncorrectEmail" contentText="Incorrect email format" />
                                                            </FormFeedback>
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="font-weight-bold" for="lblPassword">
                                                            <ContentTranslator page="Login" name="LoginPassword" contentText="Password" />
                                                        </Label>
                                                        <Input type="password" name="password" id="inputPassword" value={form.password.value} invalid={form.password.touched && (!form.password.valid || !form.password.isValidFormat)} onChange={this.handleChange} />
                                                    </FormGroup>
                                                    <Button disabled={!this.state.isFormValid} block color="primary" style={{ height: "55px", fontFamily: "IBMPlexSans-Bold" }}>
                                                        {!this.state.loading ? <ContentTranslator page="Login" name="ButtonLogin" contentText="Login" /> : <Spinner type="grow" size="md" color="light" />}
                                                    </Button>
                                                    <FormGroup>
                                                        <center>
                                                            <Link to="/forgotpassword">
                                                                <ContentTranslator page="Login" name="LoginForgotPassword" contentText="Forgot password?" />
                                                            </Link>
                                                        </center>
                                                    </FormGroup>
                                                </React.Fragment>
                                            )}
                                            {this.props.defaultCompany && this.props.samlEnabled && this.props.samlEnabled == "TRUE" && (
                                                <Button
                                                    block
                                                    style={{ height: "55px", fontFamily: "IBMPlexSans-Bold", backgroundColor: "#FFFFFF", borderColor: "#6c757d", color: "#000000" }}
                                                    onClick={() => {
                                                        window.location = "/sso/login";
                                                    }}
                                                >
                                                    <div>Login with {this.props.defaultCompany.name}</div>
                                                </Button>
                                            )}
                                            {this.props.defaultCompany && this.props.containerConfig && this.props.containerConfig.ssoMembeeEnable && this.props.containerConfig.ssoMembeeClientId != "" && this.props.containerConfig.ssoMembeeApiId != "" && (
                                                <div>
                                                    <Button
                                                        block
                                                        style={{ height: "55px", fontFamily: "IBMPlexSans-Bold", backgroundColor: "#FFFFFF", borderColor: "#6c757d", color: "#000000" }}
                                                        onClick={() => {
                                                            this.loginMembee();
                                                        }}
                                                    >
                                                        <div>Login with {this.props.defaultCompany.name}</div>
                                                    </Button>
                                                </div>
                                            )}
                                        </Form>
                                    </Col>
                                </Row>
                            </ContentWrapper>
                        </div>
                        <div id="DivLoginSpace" style={{ height: "350px", display: "none" }}>
                            &nbsp;
                        </div>
                    </React.Fragment>
                )}
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        loginResult: state.auth.loginResult,
        isAuthenticated: state.auth.token !== null,
        hasPeoples: state.auth.hasPeoples,
        isEndorser: state.auth.isEndorser,
        hasRelationships: state.auth.hasRelationships,
        manageEndorsements: state.auth.manageEndorsements,
        manageCertifications: state.auth.manageCertifications,
        manageSelfAssessed: state.auth.manageSelfAssessed,
        manageSelfProfiles: state.auth.manageSelfProfiles,
        loginWithMembee: state.auth.loginWithMembee,
        loginWithTeams: state.auth.loginWithTeams,
        receiveLicenseNotification: state.auth.receiveLicenseNotification,
        lastActiveUserNotification: state.auth.lastActiveUserNotification,
        ownJobs: state.auth.ownJobs,
        email: state.auth.email,
        assessmentId: state.auth.assessmentId,
        jobProfileReportFormat: state.auth.jobProfileReportFormat,
        selfAssessedProfileReportFormat: state.auth.selfAssessedProfileReportFormat,
        authMode: state.auth.authMode,
        samlEnabled: state.config.samlEnabled,
        restrictLoginSSOOnly: state.config.restrictLoginSSOOnly,
        defaultCompany: state.company.defaultCompany,

        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
        onAdminPerson: bindActionCreators(adminPersonActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
