import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

var _ = require("lodash");
const initialState = {
    persons: [],
    personsAdmin: [],
    error: null,
    loading: false,
    updatedRoles: [],
    isUpdating: false,
    printData: null,
    loadingPrint: false,
    isImporting: false,
    message: null,
    roleUpdating: false,
    resetPasswordSent: false,
    pageAssignments: null,
    personPageAssignments: null,
    columnDisplaySettings: null,
    columnDisplaySettingLoadings: [],
    copyProfileRequests: [],
};

const getPersonsStart = (state) => {
    return updateState(state, { error: null, persons: [], loading: true });
};

const getPersonsSuccess = (state, action) => {
    return updateState(state, {
        persons: action.persons,
        error: null,
        loading: false,
    });
};

const getPersonsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const getPersonsAdminStart = (state) => {
    return updateState(state, { error: null, personsAdmin: [], loading: true });
};

const getPersonsAdminSuccess = (state, action) => {
    return updateState(state, {
        personsAdmin: action.persons,
        error: null,
        loading: false,
    });
};

const getPersonsAdminFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const addPersonStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const addPersonSuccess = (state, action) => {
    const persons = state.persons;

    return updateState(state, {
        persons: [...persons, action.person],
        error: null,
        isUpdating: false,
    });
};

const addPersonFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updatePersonStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updatePersonSuccess = (state, action) => {
    let persons = state.persons;

    const updatedPerson = persons.find((x) => x.id == action.person.id);
    if (updatedPerson) {
        updatedPerson.firstName = action.person.firstName;
        updatedPerson.lastName = action.person.lastName;
        updatedPerson.email = action.person.email;
        updatedPerson.salutation = action.person.salutation;
        updatedPerson.position = action.person.position;
        updatedPerson.inactive = action.person.inactive;
        updatedPerson.personType = action.person.personType;
        updatedPerson.accessGroup = action.person.accessGroup;
        updatedPerson.allowNotifications = action.person.allowNotifications;
        updatedPerson.lastActivityOn = action.person.lastActivityOn;
        updatedPerson.lastActivitySystem = action.person.lastActivitySystem;
        updatedPerson.createdOn = action.person.createdOn;
        updatedPerson.countryCode = action.person.countryCode;
        updatedPerson.country = action.person.country;
        updatedPerson.location = action.person.location;
        updatedPerson.selfAssessmentReason = action.person.selfAssessmentReason;
        updatedPerson.encryptedId = action.person.encryptedId;
        updatedPerson.team = action.person.team;
        updatedPerson.usePreferredName = action.person.usePreferredName;
        updatedPerson.other = action.person.other;
        updatedPerson.department = action.person.department;
        updatedPerson.businessUnit = action.person.businessUnit;
        updatedPerson.division = action.person.division;
        updatedPerson.territory = action.person.territory;
        updatedPerson.costCentre = action.person.costCentre;
        updatedPerson.supervisor = action.person.supervisor;
        updatedPerson.validatedDate = action.person.validatedDate;
        updatedPerson.isCandidate = action.person.isCandidate;
        updatedPerson.isAnalyticsUser = action.person.isAnalyticsUser;
        updatedPerson.leaveOn = action.person.leaveOn;
        updatedPerson.accounts = action.person.accounts;
        updatedPerson.teams = action.person.teams;
        updatedPerson.profilePictureUrl = action.person.profilePictureUrl;
        updatedPerson.activityStatementInterval = action.person.activityStatementInterval;
        updatedPerson.lastUpdatedOn = action.person.lastUpdatedOn;
        updatedPerson.lastUpdatedVersion = action.person.lastUpdatedVersion;
        updatedPerson.latestAssessmentId = action.person.latestAssessmentId;
        updatedPerson.cartridgeId = action.person.cartridgeId;
        updatedPerson.hasOutstandingAction = action.person.hasOutstandingAction;
        updatedPerson.roles = action.person.roles;
        updatedPerson.groups = action.person.groups;
        updatedPerson.invitationEmailMessageId = action.person.invitationEmailMessageId;
        updatedPerson.invitationEmailMessageStatus = action.person.invitationEmailMessageStatus;
        updatedPerson.invitationEmailMessageReason = action.person.invitationEmailMessageReason;

        updatedPerson.miscellaneous1 = action.person.miscellaneous1;
        updatedPerson.miscellaneous2 = action.person.miscellaneous2;
        updatedPerson.miscellaneous3 = action.person.miscellaneous3;
        updatedPerson.miscellaneous4 = action.person.miscellaneous4;
        updatedPerson.miscellaneous5 = action.person.miscellaneous5;

        updatedPerson.skipSelfAssessmentSurvey = action.person.skipSelfAssessmentSurvey;
        updatedPerson.roleBasedSurveyId = action.person.roleBasedSurveyId;
        updatedPerson.roleBasedSurvey = action.person.roleBasedSurvey;
        updatedPerson.isContractor = action.person.isContractor;
        updatedPerson.isMentorIneligible = action.person.isMentorIneligible;
        updatedPerson.fte = action.person.fte;
    }

    return updateState(state, {
        persons: [...persons],
        error: null,
        isUpdating: false,
    });
};

const updatePersonFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deletePersonStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deletePersonSuccess = (state, action) => {
    let persons = state.persons;

    const deletedPerson = persons.find((it) => it.id == action.id);

    if (deletedPerson) {
        const index = persons.indexOf(deletedPerson);
        if (index !== -1) {
            persons.splice(index, 1);
        }
    }

    return updateState(state, {
        persons: [...persons],
        error: null,
        isUpdating: false,
    });
};

const deletePersonFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const resetPersonPasswordStart = (state) => {
    return updateState(state, { error: null, resetPasswordSent: false });
};

const resetPersonPasswordSuccess = (state, action) => {
    let persons = state.persons;

    const updatedPerson = persons.find((x) => x.id == action.id);
    if (updatedPerson) {
        updatedPerson.resetPasswordRequested = true;
    }

    return updateState(state, {
        persons: [...persons],
        error: null,
        resetPasswordSent: true,
    });
};

const resetPersonPasswordFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        resetPasswordSent: false,
    });
};

const exportPersonsDataExcelStart = (state) => {
    return updateState(state, { error: null, loadingPrint: true });
};

const exportPersonsDataExcelSuccess = (state, action) => {
    return updateState(state, {
        printData: action.printData,
        error: null,
        loadingPrint: false,
    });
};

const exportPersonsDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingPrint: false,
    });
};

const updatePersonRoleStart = (state) => {
    return updateState(state, { error: null, updatedRoles: [], roleUpdating: true });
};

const updatePersonRoleSuccess = (state, action) => {
    const updatedRoles = action.roles;
    let personId = null;
    if (updatedRoles && updatedRoles.length > 0) {
        personId = updatedRoles[0].personId;
    }

    let persons = state.persons;

    let updatedPerson = persons.find((x) => x.id == personId);
    if (updatedPerson) {
        updatedPerson.roles = [...updatedRoles];
    }

    return updateState(state, {
        updatedRoles: action.roles,
        persons: [...persons],
        error: null,
        roleUpdating: false,
    });
};

const updatePersonRoleFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
        roleUpdating: false,
    });
};

const importPersonsDataExcelStart = (state) => {
    return updateState(state, { error: null, isImporting: true, message: null });
};

const importPersonsDataExcelSuccess = (state, action) => {
    let persons = state.persons;
    const importedPersons = action.importedPersons;

    persons
        .filter((x) => importedPersons.find((y) => y.email == x.email))
        .forEach((updatedPerson) => {
            const importedPerson = importedPersons.find((it) => it.email == updatedPerson.email);
            if (importedPerson) {
                updatedPerson.firstName = importedPerson.firstName;
                updatedPerson.lastName = importedPerson.lastName;
                updatedPerson.email = importedPerson.email;
                updatedPerson.salutation = importedPerson.salutation;
                updatedPerson.position = importedPerson.position;
                updatedPerson.inactive = importedPerson.inactive;
                updatedPerson.personType = importedPerson.personType;
                updatedPerson.accessGroup = importedPerson.accessGroup;
                updatedPerson.allowNotifications = importedPerson.allowNotifications;
                updatedPerson.lastActivityOn = importedPerson.lastActivityOn;
                updatedPerson.lastActivitySystem = importedPerson.lastActivitySystem;
                updatedPerson.createdOn = importedPerson.createdOn;
                updatedPerson.countryCode = importedPerson.countryCode;
                updatedPerson.country = importedPerson.country;
                updatedPerson.location = importedPerson.location;
                updatedPerson.selfAssessmentReason = importedPerson.selfAssessmentReason;
                updatedPerson.encryptedId = importedPerson.encryptedId;
                updatedPerson.team = importedPerson.team;
                updatedPerson.usePreferredName = importedPerson.usePreferredName;
                updatedPerson.other = importedPerson.other;
                updatedPerson.department = importedPerson.department;
                updatedPerson.businessUnit = importedPerson.businessUnit;
                updatedPerson.division = importedPerson.division;
                updatedPerson.territory = importedPerson.territory;
                updatedPerson.costCentre = importedPerson.costCentre;
                updatedPerson.supervisor = importedPerson.supervisor;
                updatedPerson.validatedDate = importedPerson.validatedDate;
                updatedPerson.isCandidate = importedPerson.isCandidate;
                updatedPerson.isAnalyticsUser = importedPerson.isAnalyticsUser;
                updatedPerson.leaveOn = importedPerson.leaveOn;
                updatedPerson.accounts = importedPerson.accounts;
                updatedPerson.teams = importedPerson.teams;
                updatedPerson.profilePictureUrl = importedPerson.profilePictureUrl;
                updatedPerson.activityStatementInterval = importedPerson.activityStatementInterval;
                updatedPerson.lastUpdatedOn = importedPerson.lastUpdatedOn;
                updatedPerson.lastUpdatedVersion = importedPerson.lastUpdatedVersion;
                updatedPerson.latestAssessmentId = importedPerson.latestAssessmentId;
                updatedPerson.cartridgeId = importedPerson.cartridgeId;
                updatedPerson.hasOutstandingAction = importedPerson.hasOutstandingAction;
                updatedPerson.roles = importedPerson.roles;
                updatedPerson.groups = importedPerson.groups;

                updatedPerson.miscellaneous1 = importedPerson.miscellaneous1;
                updatedPerson.miscellaneous2 = importedPerson.miscellaneous2;
                updatedPerson.miscellaneous3 = importedPerson.miscellaneous3;
                updatedPerson.miscellaneous4 = importedPerson.miscellaneous4;
                updatedPerson.miscellaneous5 = importedPerson.miscellaneous5;

                updatedPerson.skipSelfAssessmentSurvey = importedPerson.skipSelfAssessmentSurvey;
                updatedPerson.roleBasedSurveyId = importedPerson.roleBasedSurveyId;
                updatedPerson.roleBasedSurvey = importedPerson.roleBasedSurvey;
                updatedPerson.isContractor = importedPerson.isContractor;
                updatedPerson.isMentorIneligible = importedPerson.isMentorIneligible;
                updatedPerson.fte = importedPerson.fte;
            }
        });

    const addedPersons = importedPersons.filter((x) => !persons.find((y) => x.email == y.email));

    return updateState(state, {
        error: null,
        isImporting: false,
        message: action.message,
        persons: [...persons, ...addedPersons],
    });
};

const importPersonsDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isImporting: false,
    });
};

const assignPersonsStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const assignPersonsSuccess = (state, action) => {
    const persons = state.persons;
    const updatedPersons = persons.filter((it) => action.personIds.includes(it.id));

    updatedPersons.forEach((it) => {
        it.isAssigned = true;
    });

    return updateState(state, {
        error: null,
        isUpdating: false,
        persons: [...persons],
    });
};

const assignPersonsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const invitePersonsStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const invitePersonsSuccess = (state, action) => {
    const persons = state.persons;
    const updatedPersons = persons.filter((it) => action.personIds.includes(it.id));

    updatedPersons.forEach((it) => {
        it.isInvited = true;
        it.invitedOn = new Date();
        it.invitationEmailMessageId = null;
        it.invitationEmailMessageStatus = null;
    });

    return updateState(state, {
        error: null,
        isUpdating: false,
        persons: [...persons],
    });
};

const invitePersonsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const unassignPersonsStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const unassignPersonsSuccess = (state, action) => {
    const persons = state.persons;
    const updatedPersons = persons.filter((it) => action.personIds.includes(it.id));

    updatedPersons.forEach((it) => {
        it.isAssigned = false;
        it.isInvited = false;
        it.accounts = null;
        it.teams = null;
    });

    return updateState(state, {
        error: null,
        isUpdating: false,
        persons: [...persons],
    });
};

const unassignPersonsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const addRelationshipsStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const addRelationshipsSuccess = (state, action) => {
    const addedRelationships = action.addedRelationships;
    let persons = state.persons;

    const groupedPersonAIds = _.groupBy(
        addedRelationships.map((it) => {
            return it.personAId;
        }),
        function (it) {
            return it;
        }
    );

    const groupedPersonBIds = _.groupBy(
        addedRelationships.map((it) => {
            return it.personBId;
        }),
        function (it) {
            return it;
        }
    );

    const personAIds = [];
    const personBIds = [];

    Object.keys(groupedPersonAIds).forEach((key) => {
        personAIds.push(groupedPersonAIds[key][0]);
    });

    Object.keys(groupedPersonBIds).forEach((key) => {
        personBIds.push(groupedPersonBIds[key][0]);
    });

    persons
        .filter((it) => personAIds.includes(it.id))
        .forEach((person) => {
            const thisAddedRelationships = addedRelationships.filter((it) => it.personAId == person.id);
            if (thisAddedRelationships.length > 0) {
                if (person.upRelationships) {
                    person.upRelationships = [...person.upRelationships, ...thisAddedRelationships];
                } else {
                    person.upRelationships = [...thisAddedRelationships];
                }
            }
        });

    persons
        .filter((it) => personBIds.includes(it.id))
        .forEach((person) => {
            const thisAddedRelationships = addedRelationships.filter((it) => it.personBId == person.id);
            if (thisAddedRelationships.length > 0) {
                if (person.downRelationships) {
                    person.downRelationships = [...person.downRelationships, ...thisAddedRelationships];
                } else {
                    person.downRelationships = [...thisAddedRelationships];
                }
            }
        });

    return updateState(state, {
        persons: [...persons],
        error: null,
        isUpdating: false,
    });
};

const addRelationshipsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteRelationshipsStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteRelationshipsSuccess = (state, action) => {
    const deletedRelationshipIds = action.deletedRelationshipIds;
    let persons = state.persons;

    persons
        .filter((it) => it.downRelationships.some((r) => deletedRelationshipIds.includes(r.id)) || it.upRelationships.some((r) => deletedRelationshipIds.includes(r.id)))
        .forEach((person) => {
            if (person.downRelationships.some((r) => deletedRelationshipIds.includes(r.id))) {
                person.downRelationships = person.downRelationships.filter((it) => !deletedRelationshipIds.includes(it.id));
            }

            if (person.upRelationships.some((r) => deletedRelationshipIds.includes(r.id))) {
                person.upRelationships = person.upRelationships.filter((it) => !deletedRelationshipIds.includes(it.id));
            }
        });

    return updateState(state, {
        persons: [...persons],
        error: null,
        isUpdating: false,
    });
};

const deleteRelationshipsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const getPageAssignmentsStart = (state) => {
    return updateState(state, { pageAssignments: null, error: null, loading: true });
};

const getPageAssignmentsSuccess = (state, action) => {
    return updateState(state, {
        pageAssignments: [...action.pageAssignments],
        error: null,
        loading: false,
    });
};

const getPageAssignmentsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const clearPageAssignments = (state) => {
    return updateState(state, { pageAssignments: null });
};

const getPersonPageAssignmentsStart = (state) => {
    return updateState(state, { personPageAssignments: null, error: null, loading: true });
};

const getPersonPageAssignmentsSuccess = (state, action) => {
    return updateState(state, {
        personPageAssignments: [...action.pageAssignments],
        error: null,
        loading: false,
    });
};

const getPersonPageAssignmentsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updatePersonPageAssignmentsStart = (state) => {
    return updateState(state, { personPageAssignments: null, error: null, isUpdating: true });
};

const updatePersonPageAssignmentsSuccess = (state, action) => {
    return updateState(state, {
        personPageAssignments: [...action.pageAssignments],
        error: null,
        isUpdating: false,
    });
};

const updatePersonPageAssignmentsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const generatePersonProfileReportsStart = (state) => {
    return updateState(state, { error: null, printData: null, loadingPrint: true });
};

const generatePersonProfileReportsSuccess = (state, action) => {
    return updateState(state, {
        printData: action.printData,
        error: null,
        loadingPrint: false,
    });
};

const generatePersonProfileReportsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const generateProfileReportsStart = (state) => {
    return updateState(state, { error: null, printData: null, loadingPrint: true });
};

const generateProfileReportsSuccess = (state, action) => {
    const persons = state.persons;
    const updatedPersons = action.updatedPersons;

    updatedPersons.forEach((it) => {
        const person = persons.find((p) => p.id == it.personId);
        if (person) {
            if (it.selfAssessedReport) {
                person.selfAssessedReportGenerated = true;
            }

            if (it.endorsedReport) {
                person.endorsedReportGenerated = true;
            }

            if (it.actionReport) {
                person.actionReportGenerated = true;
            }
        }
    });

    return updateState(state, {
        printData: action.printData,
        error: null,
        loadingPrint: false,
        persons: [...persons],
    });
};

const generateProfileReportsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingPrint: false,
    });
};

const updatePersonSelfAssessedApproved = (state, action) => {
    const persons = state.persons;
    const updatedPerson = action.updatedPerson;
    const person = persons.find((it) => it.id == updatedPerson.personId);
    if (person) {
        person.selfAssessedLastProfileApproved = true;
        person.selfAssessedLastApprovedOn = updatedPerson.selfAssessedLastApprovedOn;
        person.selfAssessedLastApprovedBy = updatedPerson.selfAssessedLastApprovedBy;
        person.selfAssessedLastApprovedById = updatedPerson.selfAssessedLastApprovedById;
        person.selfAssessedLastApprovedVersion = updatedPerson.selfAssessedLastApprovedVersion;
    }

    return updateState(state, {
        persons: [...persons],
    });
};

const updatePersonSelfAssessedPostPoned = (state, action) => {
    const persons = state.persons;
    const updatedPerson = action.updatedPerson;
    const person = persons.find((it) => it.id == updatedPerson.personId);
    if (person) {
        const account = person.accounts && person.accounts.length > 0 ? person.accounts.sort((a, b) => (b.createdOn > a.createdOn ? 1 : -1))[0] : null;
        if (account) {
            account.isApprovalPostponed = true;
            account.approvalPostponedOn = updatedPerson.approvalPostponedOn;
            account.approvalPostponedById = updatedPerson.approvalPostponedById;
            account.approvalPostponedBy = updatedPerson.approvalPostponedBy;
            account.approvalPostponedType = updatedPerson.approvalPostponedType;
            account.approvalPostponedComment = updatedPerson.approvalPostponedComment;
        }
    }

    return updateState(state, {
        persons: [...persons],
    });
};

const updatePersonEndorsement = (state, action) => {
    const persons = state.persons;
    const personEndorsement = action.personEndorsement;
    const person = persons.find((it) => it.id == personEndorsement.personId);
    if (person) {
        person.endorsedById = personEndorsement.endorsedById;
        person.endorsedBy = personEndorsement.endorsedBy;
        person.endorsementLatestStatus = personEndorsement.latestStatus;
        person.endorsementLastUpdatedById = personEndorsement.lastUpdatedById;
        person.endorsementLastUpdatedBy = personEndorsement.lastUpdatedBy;
        person.endorsementLastUpdated = personEndorsement.lastUpdated;
        person.endorsementLastUpdatedVersion = personEndorsement.lastUpdatedVersion;
        person.endorsementLastReviewedById = personEndorsement.lastReviewedById;
        person.endorsementLastReviewedBy = personEndorsement.lastReviewedBy;
        person.endorsementLastReviewed = personEndorsement.lastReviewed;
        person.endorsementLastReviewedVersion = personEndorsement.lastReviewedVersion;
        person.endorsementLastApprovedById = personEndorsement.lastApprovedById;
        person.endorsementLastApprovedBy = personEndorsement.lastApprovedBy;
        person.endorsementLastApproved = personEndorsement.lastApproved;
        person.endorsementLastApprovedVersion = personEndorsement.lastApprovedVersion;

        person.assessorId = personEndorsement.assessorId;
        person.assessorName = personEndorsement.assessorName;
    }

    return updateState(state, {
        persons: [...persons],
    });
};

const updatePersonEndorserStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updatePersonEndorserSuccess = (state, action) => {
    const persons = state.persons;
    const personEndorsement = action.personEndorsement;
    const person = persons.find((it) => it.id == personEndorsement.personId);
    if (person) {
        person.endorsedById = personEndorsement.endorsedById;
        person.endorsedBy = personEndorsement.endorsedBy;
        person.endorsementLatestStatus = personEndorsement.latestStatus;
        person.endorsementLastUpdatedById = personEndorsement.lastUpdatedById;
        person.endorsementLastUpdatedBy = personEndorsement.lastUpdatedBy;
        person.endorsementLastUpdated = personEndorsement.lastUpdated;
        person.endorsementLastUpdatedVersion = personEndorsement.lastUpdatedVersion;
        person.endorsementLastReviewedById = personEndorsement.lastReviewedById;
        person.endorsementLastReviewedBy = personEndorsement.lastReviewedBy;
        person.endorsementLastReviewed = personEndorsement.lastReviewed;
        person.endorsementLastReviewedVersion = personEndorsement.lastReviewedVersion;
        person.endorsementLastApprovedById = personEndorsement.lastApprovedById;
        person.endorsementLastApprovedBy = personEndorsement.lastApprovedBy;
        person.endorsementLastApproved = personEndorsement.lastApproved;
        person.endorsementLastApprovedVersion = personEndorsement.lastApprovedVersion;

        person.assessorId = personEndorsement.assessorId;
        person.assessorName = personEndorsement.assessorName;
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        persons: [...persons],
    });
};

const updatePersonEndorserFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const clearColumnDisplaySettings = (state) => {
    return updateState(state, { columnDisplaySettings: null, columnDisplaySettingLoadings: [] });
};

const getColumnDisplaySettingsStart = (state) => {
    return updateState(state, { columnDisplaySettings: null, error: null });
};

const getColumnDisplaySettingsSuccess = (state, action) => {
    return updateState(state, {
        columnDisplaySettings: [...action.columnDisplaySettings],
        error: null,
        columnDisplaySettingLoadings: [],
    });
};

const getColumnDisplaySettingsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
    });
};

const updateColumnDisplaySettingStart = (state, action) => {
    let columnDisplaySettingLoadings = state.columnDisplaySettingLoadings;
    let columnDisplaySettingLoading = columnDisplaySettingLoadings.find((it) => it.id == action.id);
    if (columnDisplaySettingLoading) {
        columnDisplaySettingLoading.loading = true;
    } else {
        columnDisplaySettingLoadings.push({
            id: action.id,
            loading: true,
        });
    }

    return updateState(state, { error: null, columnDisplaySettingLoadings: [...columnDisplaySettingLoadings] });
};

const updateColumnDisplaySettingSuccess = (state, action) => {
    const updatedColumnDisplaySetting = action.updatedColumnDisplaySetting;
    let columnDisplaySettings = state.columnDisplaySettings;
    let oriColumnDisplaySetting = columnDisplaySettings.find((it) => it.id == updatedColumnDisplaySetting.id);
    if (oriColumnDisplaySetting) {
        oriColumnDisplaySetting.visible = updatedColumnDisplaySetting.visible;
    } else {
        columnDisplaySettings.push(updatedColumnDisplaySetting);
    }

    let columnDisplaySettingLoadings = state.columnDisplaySettingLoadings;

    let columnDisplaySettingLoading = columnDisplaySettingLoadings.find((it) => it.id == updatedColumnDisplaySetting.id);

    if (columnDisplaySettingLoading) {
        columnDisplaySettingLoading.loading = false;
    }

    return updateState(state, {
        columnDisplaySettingLoadings: [...columnDisplaySettingLoadings],
        columnDisplaySettings: [...action.columnDisplaySettings],
        error: null,
    });
};

const updateColumnDisplaySettingFail = (state, action) => {
    let columnDisplaySettingLoadings = state.columnDisplaySettingLoadings;
    let columnDisplaySettingLoading = columnDisplaySettingLoadings.find((it) => it.id == action.id);

    if (columnDisplaySettingLoading) {
        columnDisplaySettingLoading.loading = false;
    }

    return updateState(state, {
        error: action.error,
        columnDisplaySettingLoadings: [...columnDisplaySettingLoadings],
    });
};

const requestCopySelfProfilesAsEndorsedStart = (state) => {
    return updateState(state, { error: null, isUpdating: true, copyProfileRequests: [] });
};

const requestCopySelfProfilesAsEndorsedSuccess = (state, action) => {
    let persons = state.persons;
    const copyProfileRequests = action.copyProfileRequests;

    copyProfileRequests.forEach((copyProfileRequest) => {
        const updatedPerson = persons.find((x) => x.id == copyProfileRequest.personId && x.selfAssessedLastApprovedVersion == copyProfileRequest.version);
        if (updatedPerson) {
            updatedPerson.selfAssessedCopyProfileRequested = true;
            updatedPerson.selfAssessedCopyProfileRequestedOn = copyProfileRequest.createdOn;
            updatedPerson.selfAssessedCopyProfileRequestStatus = copyProfileRequest.status;
        }
    });

    return updateState(state, {
        persons: [...persons],
        error: null,
        isUpdating: false,
        copyProfileRequests,
    });
};

const requestCopySelfProfilesAsEndorsedFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateCopySelfProfilesAsEndorsedStatus = (state, action) => {
    let persons = state.persons;
    const copyProfileRequestResponse = action.copyProfileRequestResponse;

    const updatedPerson = persons.find((x) => x.id == copyProfileRequestResponse.personId && x.selfAssessedLastApprovedVersion == copyProfileRequestResponse.version);
    if (updatedPerson) {
        updatedPerson.selfAssessedCopyProfileRequested = true;
        updatedPerson.selfAssessedCopyProfileRequestedOn = copyProfileRequestResponse.createdOn;
        updatedPerson.selfAssessedCopyProfileRequestStatus = copyProfileRequestResponse.status;
    }

    return updateState(state, {
        persons: [...persons],
        error: null,
        isUpdating: false,
    });
};

const exportPersonsProfilesStart = (state) => {
    return updateState(state, { error: null, loadingPrint: true });
};

const exportPersonsProfilesSuccess = (state, action) => {
    return updateState(state, {
        printData: action.printData,
        error: null,
        loadingPrint: false,
    });
};

const exportPersonsProfilesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingPrint: false,
    });
};

const importPersonsProfilesStart = (state) => {
    return updateState(state, { error: null, isImporting: true, message: null });
};

const importPersonsProfilesSuccess = (state, action) => {
    let persons = state.persons;
    const importedPersons = action.importedPersons;

    persons
        .filter((x) => importedPersons.find((y) => y.email == x.email))
        .forEach((updatedPerson) => {
            const importedPerson = importedPersons.find((it) => it.email == updatedPerson.email);
            if (importedPerson) {
                updatedPerson.firstName = importedPerson.firstName;
                updatedPerson.lastName = importedPerson.lastName;
                updatedPerson.email = importedPerson.email;
                updatedPerson.salutation = importedPerson.salutation;
                updatedPerson.position = importedPerson.position;
                updatedPerson.inactive = importedPerson.inactive;
                updatedPerson.personType = importedPerson.personType;
                updatedPerson.accessGroup = importedPerson.accessGroup;
                updatedPerson.allowNotifications = importedPerson.allowNotifications;
                updatedPerson.lastActivityOn = importedPerson.lastActivityOn;
                updatedPerson.lastActivitySystem = importedPerson.lastActivitySystem;
                updatedPerson.createdOn = importedPerson.createdOn;
                updatedPerson.countryCode = importedPerson.countryCode;
                updatedPerson.country = importedPerson.country;
                updatedPerson.location = importedPerson.location;
                updatedPerson.selfAssessmentReason = importedPerson.selfAssessmentReason;
                updatedPerson.encryptedId = importedPerson.encryptedId;
                updatedPerson.team = importedPerson.team;
                updatedPerson.usePreferredName = importedPerson.usePreferredName;
                updatedPerson.other = importedPerson.other;
                updatedPerson.department = importedPerson.department;
                updatedPerson.businessUnit = importedPerson.businessUnit;
                updatedPerson.division = importedPerson.division;
                updatedPerson.territory = importedPerson.territory;
                updatedPerson.costCentre = importedPerson.costCentre;
                updatedPerson.supervisor = importedPerson.supervisor;
                updatedPerson.validatedDate = importedPerson.validatedDate;
                updatedPerson.isCandidate = importedPerson.isCandidate;
                updatedPerson.isAnalyticsUser = importedPerson.isAnalyticsUser;
                updatedPerson.leaveOn = importedPerson.leaveOn;
                updatedPerson.accounts = importedPerson.accounts;
                updatedPerson.teams = importedPerson.teams;
                updatedPerson.profilePictureUrl = importedPerson.profilePictureUrl;
                updatedPerson.activityStatementInterval = importedPerson.activityStatementInterval;
                updatedPerson.lastUpdatedOn = importedPerson.lastUpdatedOn;
                updatedPerson.lastUpdatedVersion = importedPerson.lastUpdatedVersion;
                updatedPerson.latestAssessmentId = importedPerson.latestAssessmentId;
                updatedPerson.cartridgeId = importedPerson.cartridgeId;
                updatedPerson.hasOutstandingAction = importedPerson.hasOutstandingAction;
                updatedPerson.roles = importedPerson.roles;
                updatedPerson.groups = importedPerson.groups;

                updatedPerson.totalQuestions = importedPerson.totalQuestions;
                updatedPerson.answeredQuestions = importedPerson.answeredQuestions;
                updatedPerson.percentage = importedPerson.percentage;

                updatedPerson.miscellaneous1 = importedPerson.miscellaneous1;
                updatedPerson.miscellaneous2 = importedPerson.miscellaneous2;
                updatedPerson.miscellaneous3 = importedPerson.miscellaneous3;
                updatedPerson.miscellaneous4 = importedPerson.miscellaneous4;
                updatedPerson.miscellaneous5 = importedPerson.miscellaneous5;
            }
        });

    const addedPersons = importedPersons.filter((x) => !persons.find((y) => x.email == y.email));

    return updateState(state, {
        error: null,
        isImporting: false,
        message: action.message,
        persons: [...persons, ...addedPersons],
    });
};

const importPersonsProfilesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isImporting: false,
    });
};

const approveProfileRequestsStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const approveProfileRequestsSuccess = (state, action) => {
    const persons = state.persons;
    const updatedPersons = action.updatedPersons;

    updatedPersons.forEach((updatedPerson) => {
        const person = persons.find((it) => it.id == updatedPerson.id);
        if (person) {
            person.selfAssessedLastProfileVersion = updatedPerson.selfAssessedLastProfileVersion;
            person.selfAssessedLastProfileUpdatedOn = updatedPerson.selfAssessedLastProfileUpdatedOn;
            person.selfAssessedLastProfileApproved = updatedPerson.selfAssessedLastProfileApproved;
            person.selfAssessedLastProfileUpdatedById = updatedPerson.selfAssessedLastProfileUpdatedById;
            person.selfAssessedLastProfileUpdatedBy = updatedPerson.selfAssessedLastProfileUpdatedBy;

            person.selfAssessedLastApprovedById = updatedPerson.selfAssessedLastProfileVersion;
            person.selfAssessedLastApprovedBy = updatedPerson.selfAssessedLastApprovedBy;
            person.selfAssessedLastApprovedOn = updatedPerson.selfAssessedLastApprovedOn;
            person.selfAssessedLastApprovedVersion = updatedPerson.selfAssessedLastApprovedVersion;

            person.selfAssessedLastProfileNumberOfChange = updatedPerson.selfAssessedLastProfileNumberOfChange;
        }
    });

    return updateState(state, {
        error: null,
        isUpdating: false,
        persons: [...persons],
    });
};

const approveProfileRequestsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PERSONS_START:
            return getPersonsStart(state);
        case actionTypes.GET_PERSONS_SUCCESS:
            return getPersonsSuccess(state, action);
        case actionTypes.GET_PERSONS_FAIL:
            return getPersonsFail(state, action);

        case actionTypes.GET_ADMIN_PERSONS_START:
            return getPersonsAdminStart(state);
        case actionTypes.GET_ADMIN_PERSONS_SUCCESS:
            return getPersonsAdminSuccess(state, action);
        case actionTypes.GET_ADMIN_PERSONS_FAIL:
            return getPersonsAdminFail(state, action);

        case actionTypes.ADD_PERSON_START:
            return addPersonStart(state);
        case actionTypes.ADD_PERSON_SUCCESS:
            return addPersonSuccess(state, action);
        case actionTypes.ADD_PERSON_FAIL:
            return addPersonFail(state, action);

        case actionTypes.UPDATE_PERSON_START:
            return updatePersonStart(state);
        case actionTypes.UPDATE_PERSON_SUCCESS:
            return updatePersonSuccess(state, action);
        case actionTypes.UPDATE_PERSON_FAIL:
            return updatePersonFail(state, action);

        case actionTypes.DELETE_PERSON_START:
            return deletePersonStart(state);
        case actionTypes.DELETE_PERSON_SUCCESS:
            return deletePersonSuccess(state, action);
        case actionTypes.DELETE_PERSON_FAIL:
            return deletePersonFail(state, action);

        case actionTypes.RESET_PERSON_PASSWORD_START:
            return resetPersonPasswordStart(state);
        case actionTypes.RESET_PERSON_PASSWORD_SUCCESS:
            return resetPersonPasswordSuccess(state, action);
        case actionTypes.RESET_PERSON_PASSWORD_FAIL:
            return resetPersonPasswordFail(state, action);

        case actionTypes.EXPORT_PERSONS_DATA_START:
            return exportPersonsDataExcelStart(state);
        case actionTypes.EXPORT_PERSONS_DATA_SUCCESS:
            return exportPersonsDataExcelSuccess(state, action);
        case actionTypes.EXPORT_PERSONS_DATA_FAIL:
            return exportPersonsDataExcelFail(state, action);

        case actionTypes.UPDATE_PERSON_ROLES_START:
            return updatePersonRoleStart(state);
        case actionTypes.UPDATE_PERSON_ROLES_SUCCESS:
            return updatePersonRoleSuccess(state, action);
        case actionTypes.UPDATE_PERSON_ROLES_FAIL:
            return updatePersonRoleFail(state, action);

        case actionTypes.IMPORT_PERSONS_DATA_START:
            return importPersonsDataExcelStart(state);
        case actionTypes.IMPORT_PERSONS_DATA_SUCCESS:
            return importPersonsDataExcelSuccess(state, action);
        case actionTypes.IMPORT_PERSONS_DATA_FAIL:
            return importPersonsDataExcelFail(state, action);

        case actionTypes.ASSIGN_PERSONS_START:
            return assignPersonsStart(state);
        case actionTypes.ASSIGN_PERSONS_SUCCESS:
            return assignPersonsSuccess(state, action);
        case actionTypes.ASSIGN_PERSONS_FAIL:
            return assignPersonsFail(state, action);

        case actionTypes.INVITE_PERSONS_START:
            return invitePersonsStart(state);
        case actionTypes.INVITE_PERSONS_SUCCESS:
            return invitePersonsSuccess(state, action);
        case actionTypes.INVITE_PERSONS_FAIL:
            return invitePersonsFail(state, action);

        case actionTypes.UNASSIGN_PERSONS_START:
            return unassignPersonsStart(state);
        case actionTypes.UNASSIGN_PERSONS_SUCCESS:
            return unassignPersonsSuccess(state, action);
        case actionTypes.UNASSIGN_PERSONS_FAIL:
            return unassignPersonsFail(state, action);

        case actionTypes.ADD_RELATIONSHIPS_START:
            return addRelationshipsStart(state);
        case actionTypes.ADD_RELATIONSHIPS_SUCCESS:
            return addRelationshipsSuccess(state, action);
        case actionTypes.ADD_RELATIONSHIPS_FAIL:
            return addRelationshipsFail(state, action);

        case actionTypes.DELETE_RELATIONSHIPS_START:
            return deleteRelationshipsStart(state);
        case actionTypes.DELETE_RELATIONSHIPS_SUCCESS:
            return deleteRelationshipsSuccess(state, action);
        case actionTypes.DELETE_RELATIONSHIPS_FAIL:
            return deleteRelationshipsFail(state, action);

        case actionTypes.GET_PAGE_ASSIGNMENTS_START:
            return getPageAssignmentsStart(state);
        case actionTypes.GET_PAGE_ASSIGNMENTS_SUCCESS:
            return getPageAssignmentsSuccess(state, action);
        case actionTypes.GET_PAGE_ASSIGNMENTS_FAIL:
            return getPageAssignmentsFail(state, action);
        case actionTypes.CLEAR_PAGE_ASSIGNMENTS:
            return clearPageAssignments(state, action);

        case actionTypes.GET_PERSON_PAGE_ASSIGNMENTS_START:
            return getPersonPageAssignmentsStart(state);
        case actionTypes.GET_PERSON_PAGE_ASSIGNMENTS_SUCCESS:
            return getPersonPageAssignmentsSuccess(state, action);
        case actionTypes.GET_PERSON_PAGE_ASSIGNMENTS_FAIL:
            return getPersonPageAssignmentsFail(state, action);

        case actionTypes.UPDATE_PERSON_PAGE_ASSIGNMENTS_START:
            return updatePersonPageAssignmentsStart(state);
        case actionTypes.UPDATE_PERSON_PAGE_ASSIGNMENTS_SUCCESS:
            return updatePersonPageAssignmentsSuccess(state, action);
        case actionTypes.UPDATE_PERSON_PAGE_ASSIGNMENTS_FAIL:
            return updatePersonPageAssignmentsFail(state, action);

        case actionTypes.GENERATE_PERSON_PROFILE_REPORTS_START:
            return generatePersonProfileReportsStart(state);
        case actionTypes.GENERATE_PERSON_PROFILE_REPORTS_SUCCESS:
            return generatePersonProfileReportsSuccess(state, action);
        case actionTypes.GENERATE_PERSON_PROFILE_REPORTS_FAIL:
            return generatePersonProfileReportsFail(state, action);

        case actionTypes.GENERATE_PROFILE_REPORTS_START:
            return generateProfileReportsStart(state);
        case actionTypes.GENERATE_PROFILE_REPORTS_SUCCESS:
            return generateProfileReportsSuccess(state, action);
        case actionTypes.GENERATE_PROFILE_REPORTS_FAIL:
            return generateProfileReportsFail(state, action);

        case actionTypes.UPDATE_PERSON_ENDORSEMENT:
            return updatePersonEndorsement(state, action);

        case actionTypes.UPDATE_PERSON_SELFASSESSED_APPROVED:
            return updatePersonSelfAssessedApproved(state, action);
        case actionTypes.UPDATE_PERSON_SELFASSESSED_POSTPONED:
            return updatePersonSelfAssessedPostPoned(state, action);

        case actionTypes.UPDATE_PERSON_ENDORSER_START:
            return updatePersonEndorserStart(state, action);
        case actionTypes.UPDATE_PERSON_ENDORSER_SUCCESS:
            return updatePersonEndorserSuccess(state, action);
        case actionTypes.UPDATE_PERSON_ENDORSER_FAIL:
            return updatePersonEndorserFail(state, action);

        case actionTypes.GET_ADMIN_COLUMN_DISPLAY_SETTINGS_START:
            return getColumnDisplaySettingsStart(state);
        case actionTypes.GET_ADMIN_COLUMN_DISPLAY_SETTINGS_SUCCESS:
            return getColumnDisplaySettingsSuccess(state, action);
        case actionTypes.GET_ADMIN_COLUMN_DISPLAY_SETTINGS_FAIL:
            return getColumnDisplaySettingsFail(state, action);
        case actionTypes.CLEAR_COLUMN_DISPLAY_SETTINGS:
            return clearColumnDisplaySettings(state, action);

        case actionTypes.UPDATE_ADMIN_COLUMN_DISPLAY_SETTING_START:
            return updateColumnDisplaySettingStart(state, action);
        case actionTypes.UPDATE_ADMIN_COLUMN_DISPLAY_SETTING_SUCCESS:
            return updateColumnDisplaySettingSuccess(state, action);
        case actionTypes.UPDATE_ADMIN_COLUMN_DISPLAY_SETTING_FAIL:
            return updateColumnDisplaySettingFail(state, action);

        case actionTypes.REQUEST_COPY_SELF_PROFILES_AS_ENDORSED_START:
            return requestCopySelfProfilesAsEndorsedStart(state);
        case actionTypes.REQUEST_COPY_SELF_PROFILES_AS_ENDORSED_SUCCESS:
            return requestCopySelfProfilesAsEndorsedSuccess(state, action);
        case actionTypes.REQUEST_COPY_SELF_PROFILES_AS_ENDORSED_FAIL:
            return requestCopySelfProfilesAsEndorsedFail(state, action);
        case actionTypes.UPDATE_COPY_SELF_PROFILES_AS_ENDORSED_STATUS:
            return updateCopySelfProfilesAsEndorsedStatus(state, action);

        case actionTypes.EXPORT_PERSONS_PROFILES_START:
            return exportPersonsProfilesStart(state);
        case actionTypes.EXPORT_PERSONS_PROFILES_SUCCESS:
            return exportPersonsProfilesSuccess(state, action);
        case actionTypes.EXPORT_PERSONS_PROFILES_FAIL:
            return exportPersonsProfilesFail(state, action);

        case actionTypes.IMPORT_PERSONS_PROFILES_START:
            return importPersonsProfilesStart(state);
        case actionTypes.IMPORT_PERSONS_PROFILES_SUCCESS:
            return importPersonsProfilesSuccess(state, action);
        case actionTypes.IMPORT_PERSONS_PROFILES_FAIL:
            return importPersonsProfilesFail(state, action);

        case actionTypes.APPROVE_SKILL_PROFILES_START:
            return approveProfileRequestsStart(state, action);
        case actionTypes.APPROVE_SKILL_PROFILES_SUCCESS:
            return approveProfileRequestsSuccess(state, action);
        case actionTypes.APPROVE_SKILL_PROFILES_FAIL:
            return approveProfileRequestsFail(state, action);

        default:
            return state;
    }
};

export default reducer;
