import ActionPlanResponsibility from "./TableActionPlanResponsibility";
import ActionPlanSkillProfiles from "./TableActionPlanSkillProfiles";
import React, { Component } from "react";
import { Container, Spinner, Table } from "reactstrap";

export class TableActionPlan extends Component {
    static displayName = TableActionPlan.name;

    constructor(props) {
        super(props);

        this.state = {
            showTable: false,
            categories: this.props.categories,
            extraFrameworkCapabilities: this.props.extraFrameworkCapabilities,
            jobLorProfiles: this.props.jobLorProfiles,
            jobSkillProfiles: this.props.jobSkillProfiles,
            lorProfiles: this.props.lorProfiles,
            skillProfiles: this.props.skillProfiles,
            optionsActionSkills: this.props.optionsActionSkills,
        };
    }

    componentDidMount() {
        this.processJobGapData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loadingAction !== this.props.loadingAction || prevProps.loadingProfile !== this.props.loadingProfile || prevProps.selectedJob !== this.props.selectedJob || prevProps.actions !== this.props.actions) {
            this.processJobGapData();
        }
    }

    handleActionClick = (value) => {
        this.setState({
            showTable: value,
        });
    };

    processJobGapData = () => {
        if (!this.props.loadingAction && !this.props.loadingProfile) {
            const { actions, jobLorProfiles, jobSkillProfiles, lorProfiles, skillProfiles, selectedJob } = this.props;
            let { categories, extraFrameworkCapabilities } = this.state;

            categories = categories.map((category) => {
                return {
                    id: category.id,
                    name: category.name,
                    isExtraFramework: category.isExtraFramework,
                    description: category.description,
                    colour: category.colour,
                    sfiaVersion: category.sfiaVersion,
                    subCategories: category.subCategories.map((subCategory) => {
                        return {
                            id: subCategory.id,
                            name: subCategory.name,
                            description: subCategory.description,
                            colour: subCategory.colour,
                            skillColour: subCategory.skillColour,
                            skills: subCategory.skills.map((skill) => {
                                let skillNameShown = false;

                                return {
                                    id: skill.id,
                                    name: skill.name,
                                    skillsCode: skill.skillsCode,
                                    description: skill.description,
                                    isHidden: skill.isHidden,
                                    levels: skill.levels.map((level) => {
                                        const skillProfile = skillProfiles.find((x) => x.skillCode == skill.skillsCode && x.level == level.level);
                                        const jobSkillProfile = jobSkillProfiles.find((x) => x.jobId == selectedJob.id && x.skillCode == skill.skillsCode && x.level == level.level);

                                        if (jobSkillProfile && skillProfile) {
                                            if (skillProfile.numericVal == 1 || skillProfile.stringVal == "M") {
                                                jobSkillProfile.actioned = true;
                                            }
                                        }

                                        const action = actions.find((actions) => actions.skills.some((it) => it.code == skill.skillsCode && it.level == level.level));

                                        level = {
                                            skillNameShown: false,
                                            show: false,
                                            id: level.id,
                                            level: level.level,
                                            description: level.description,
                                            skillProfile: skillProfile,
                                            jobSkillProfile: jobSkillProfile,
                                            action: action,
                                        };

                                        if (!skillNameShown) {
                                            if (!category.isExtraFramework == true) {
                                                if ((level.skillProfile && level.jobSkillProfile && level.skillProfile.numericVal < 1) || (!level.skillProfile && level.jobSkillProfile) || this.state.showAllSkill) {
                                                    level.skillNameShown = true;
                                                    skillNameShown = true;
                                                }
                                            } else {
                                                const topExtraFrameworkCapability = [...extraFrameworkCapabilities].sort((a, b) => b.weight - a.weight)[0];
                                                if ((level.skillProfile && level.jobSkillProfile && level.skillProfile.numericVal < topExtraFrameworkCapability.weight) || (!level.skillProfile && level.jobSkillProfile) || this.state.showAllSkill) {
                                                    level.skillNameShown = true;
                                                    skillNameShown = true;
                                                }
                                            }
                                        }

                                        if (!category.isExtraFramework == true) {
                                            if ((level.skillProfile && level.jobSkillProfile && level.skillProfile.numericVal < 1) || (!level.skillProfile && level.jobSkillProfile)) {
                                                level.show = true;
                                            }
                                        } else {
                                            const topExtraFrameworkCapability = [...extraFrameworkCapabilities].sort((a, b) => b.weight - a.weight)[0];
                                            if ((level.skillProfile && level.jobSkillProfile && level.skillProfile.numericVal < topExtraFrameworkCapability.weight) || (!level.skillProfile && level.jobSkillProfile)) {
                                                level.show = true;
                                            }
                                        }

                                        return level;
                                    }),
                                };
                            }),
                        };
                    }),
                };
            });

            jobLorProfiles.map((jobLorProfile) => {
                const lorProfile = lorProfiles.find((it) => it.lorCode == jobLorProfile.lorCode && it.level >= jobLorProfile.level);
                const plan = actions.find((it) => it.skills.some((skill) => skill.code == jobLorProfile.lorCode && skill.level >= jobLorProfile.level));

                if (lorProfile || plan) {
                    jobLorProfile.actioned = true;
                }
            });

            jobSkillProfiles.map((jobSkillProfile) => {
                const plan = actions.find((it) => it.skills.some((skill) => skill.code == jobSkillProfile.skillCode && skill.level == jobSkillProfile.level));
                if (plan) {
                    jobSkillProfile.actioned = true;
                }
            });

            let optionsActionSkills = this.props.optionsActionSkills;
            optionsActionSkills.forEach((actionSkill) => {
                actionSkill.skills.forEach((skill) => {
                    if (
                        actions.filter((x) => x.skills.some((y) => y.code == skill.skillCode && y.level == skill.skillLevel)).length > 0 ||
                        jobLorProfiles.filter((it) => it.lorCode == skill.skillCode && it.level == skill.skillLevel).length > 0 ||
                        jobSkillProfiles.filter((it) => it.skillCode == skill.skillCode && it.level == skill.skillLevel).length > 0
                    ) {
                        skill.actioned = true;
                    }
                });
            });

            this.props.returnOptionActionSkills(optionsActionSkills);

            this.setState({
                categories,
                jobLorProfiles,
                jobSkillProfiles,
                lorProfiles,
                skillProfiles,
                optionsActionSkills,
            });
        }
    };

    render() {
        return (
            <Container className="p-0 mt-4" style={{ fontSize: "11px" }}>
                {this.props.loadingProfile ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <Table>
                            <ActionPlanResponsibility
                                showAll={this.props.showAll}
                                showHiddenSkills={this.props.showHiddenSkills}
                                selectedJob={this.props.selectedJob}
                                currentJob={this.props.currentJob}
                                futureJob={this.props.futureJob}
                                actions={this.props.actions}
                                jobLorProfiles={this.state.jobLorProfiles}
                                jobSkillProfiles={this.state.jobSkillProfiles}
                                selectedOptionProfile={this.props.selectedOptionProfile}
                                categories={this.state.categories}
                                optionsActionTypes={this.props.optionsActionTypes}
                                optionsActionSkills={this.state.optionsActionSkills}
                                handleActionPlanAdded={this.props.handleActionPlanAdded}
                                providers={this.props.providers}
                                lorProfiles={this.state.lorProfiles}
                                skillProfiles={this.state.skillProfiles}
                                allowUpdate={this.props.allowUpdate}
                                optionsJob={this.props.optionsJob}
                            ></ActionPlanResponsibility>
                        </Table>
                        <Table>
                            <ActionPlanSkillProfiles
                                showAll={this.props.showAll}
                                showHiddenSkills={this.props.showHiddenSkills}
                                actions={this.props.actions}
                                jobLorProfiles={this.state.jobLorProfiles}
                                jobSkillProfiles={this.state.jobSkillProfiles}
                                selectedOptionProfile={this.props.selectedOptionProfile}
                                categories={this.state.categories}
                                lorProfiles={this.state.lorProfiles}
                                skillProfiles={this.state.skillProfiles}
                                optionsActionTypes={this.props.optionsActionTypes}
                                optionsActionSkills={this.state.optionsActionSkills}
                                handleActionPlanAdded={this.props.handleActionPlanAdded}
                                providers={this.props.providers}
                                allowUpdate={this.props.allowUpdate}
                                optionsJob={this.props.optionsJob}
                            ></ActionPlanSkillProfiles>
                        </Table>
                    </React.Fragment>
                )}
            </Container>
        );
    }
}
