import ActionPlanAPI from "./../../common/api/service/ActionPlanService";
import * as actionTypes from "./actionTypes";

export const getActionTypesStart = () => {
    return {
        type: actionTypes.GET_ACTION_TYPES_START,
    };
};

export const getActionTypesSuccess = (arrActionTypes) => {
    return {
        type: actionTypes.GET_ACTION_TYPES_SUCCESS,
        actionTypes: arrActionTypes,
    };
};

export const getActionTypesFail = (error) => {
    return {
        type: actionTypes.GET_ACTION_TYPES_FAIL,
        error: error,
    };
};

export const getActionTypes = () => {
    return (dispatch) => {
        dispatch(getActionTypesStart());

        return ActionPlanAPI.getActionTypes()
            .then((res) => {
                if (res) {
                    dispatch(getActionTypesSuccess(res));
                } else {
                    dispatch(getActionTypesFail(res));
                }
            })
            .catch((err) => {
                dispatch(getActionTypesFail(err));
            });
    };
};

export const getActionSkillsStart = () => {
    return {
        type: actionTypes.GET_ACTION_SKILLS_START,
    };
};

export const getActionSkillsSuccess = (actionSkills) => {
    return {
        type: actionTypes.GET_ACTION_SKILLS_SUCCESS,
        actionSkills: actionSkills,
    };
};

export const getActionSkillsFail = (error) => {
    return {
        type: actionTypes.GET_ACTION_SKILLS_FAIL,
        error: error,
    };
};

export const getActionSkills = () => {
    return (dispatch) => {
        dispatch(getActionSkillsStart());

        return ActionPlanAPI.getActionSkills()
            .then((res) => {
                if (res) {
                    dispatch(getActionSkillsSuccess(res));
                } else {
                    dispatch(getActionSkillsFail(res));
                }
            })
            .catch((err) => {
                dispatch(getActionSkillsFail(err));
            });
    };
};

export const printMyActionReportStart = () => {
    return {
        type: actionTypes.PRINT_MY_ACTION_REPORT_START,
    };
};

export const printMyActionReportSuccess = (printData) => {
    return {
        type: actionTypes.PRINT_MY_ACTION_REPORT_SUCCESS,
        printData,
    };
};

export const printMyActionReportFail = (error) => {
    return {
        type: actionTypes.PRINT_MY_ACTION_REPORT_FAIL,
        error: error,
    };
};

export const printMyActionReport = () => {
    return (dispatch) => {
        dispatch(printMyActionReportStart());

        return ActionPlanAPI.printActionReport()
            .then((res) => {
                dispatch(printMyActionReportSuccess(res));
            })
            .catch((err) => {
                dispatch(printMyActionReportFail(err));
            });
    };
};

export const printManagedPeopleActionReportStart = () => {
    return {
        type: actionTypes.PRINT_MANAGED_PEOPLE_ACTION_REPORT_START,
    };
};

export const printManagedPeopleActionReportSuccess = (printData) => {
    return {
        type: actionTypes.PRINT_MANAGED_PEOPLE_ACTION_REPORT_SUCCESS,
        printData,
    };
};

export const printManagedPeopleActionReportFail = (error) => {
    return {
        type: actionTypes.PRINT_MANAGED_PEOPLE_ACTION_REPORT_FAIL,
        error: error,
    };
};

export const printManagedPeopleActionReport = (email) => {
    return (dispatch) => {
        dispatch(printManagedPeopleActionReportStart());

        return ActionPlanAPI.printManagedPeopleActionReport(email)
            .then((res) => {
                dispatch(printManagedPeopleActionReportSuccess(res));
            })
            .catch((err) => {
                dispatch(printManagedPeopleActionReportFail(err));
            });
    };
};

export const getInterventionsStart = () => {
    return {
        type: actionTypes.GET_INTERVENTIONS_START,
    };
};

export const getInterventionsSuccess = (interventions) => {
    return {
        type: actionTypes.GET_INTERVENTIONS_SUCCESS,
        interventions: interventions,
    };
};

export const getInterventionsFail = (error) => {
    return {
        type: actionTypes.GET_INTERVENTIONS_FAIL,
        error: error,
    };
};

export const getInterventions = (filter) => {
    return (dispatch) => {
        dispatch(getInterventionsStart());

        return ActionPlanAPI.getFilteredInterventions(filter)
            .then((res) => {
                dispatch(getInterventionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getInterventionsFail(err));
            });
    };
};

export const updateInterventionHiddenStart = () => {
    return {
        type: actionTypes.UPDATE_INTERVENTION_HIDDEN_START,
    };
};

export const updateInterventionHiddenSuccess = (intervention) => {
    return {
        type: actionTypes.UPDATE_INTERVENTION_HIDDEN_SUCCESS,
        intervention,
    };
};

export const updateInterventionHiddenFail = (error) => {
    return {
        type: actionTypes.UPDATE_INTERVENTION_HIDDEN_FAIL,
        error: error,
    };
};

export const updateHideIntervention = (id) => {
    return (dispatch) => {
        dispatch(updateInterventionHiddenStart());
        return ActionPlanAPI.updateHideIntervetion(id)
            .then((res) => {
                dispatch(updateInterventionHiddenSuccess(res));
            })
            .catch((err) => {
                dispatch(updateInterventionHiddenFail(err));
            });
    };
};

export const updateUnHideIntervention = (id) => {
    return (dispatch) => {
        dispatch(updateInterventionHiddenStart());
        return ActionPlanAPI.updateUnHideIntervetion(id)
            .then((res) => {
                dispatch(updateInterventionHiddenSuccess(res));
            })
            .catch((err) => {
                dispatch(updateInterventionHiddenFail(err));
            });
    };
};

export const getMyActionsStart = () => {
    return {
        type: actionTypes.GET_MY_ACTIONS_START,
    };
};

export const getMyActionsSuccess = (actions) => {
    return {
        type: actionTypes.GET_MY_ACTIONS_SUCCESS,
        actions,
    };
};

export const getMyActionsFail = (error) => {
    return {
        type: actionTypes.GET_MY_ACTIONS_FAIL,
        error: error,
    };
};

export const getMyActions = () => {
    return (dispatch) => {
        dispatch(getMyActionsStart());

        return ActionPlanAPI.getActions()
            .then((res) => {
                dispatch(getMyActionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getMyActionsFail(err));
            });
    };
};

export const getManagedPeopleActionsStart = () => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_ACTIONS_START,
    };
};

export const getManagedPeopleActionsSuccess = (actions) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_ACTIONS_SUCCESS,
        actions,
    };
};

export const getManagedPeopleActionsFail = (error) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_ACTIONS_FAIL,
        error: error,
    };
};

export const getManagedPeopleActions = (email) => {
    return (dispatch) => {
        dispatch(getManagedPeopleActionsStart());

        return ActionPlanAPI.getManagedPeopleActions(email)
            .then((res) => {
                dispatch(getManagedPeopleActionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getManagedPeopleActionsFail(err));
            });
    };
};

export const createActionStart = () => {
    return {
        type: actionTypes.CREATE_ACTION_START,
    };
};

export const createActionSuccess = (addedAction) => {
    return {
        type: actionTypes.CREATE_ACTION_SUCCESS,
        addedAction,
    };
};

export const createActionFail = (error) => {
    return {
        type: actionTypes.CREATE_ACTION_FAIL,
        error: error,
    };
};

export const createAction = (action) => {
    return (dispatch) => {
        dispatch(createActionStart());
        return ActionPlanAPI.createAction(action)
            .then((res) => {
                dispatch(createActionSuccess(res));
            })
            .catch((err) => {
                dispatch(createActionFail(err));
            });
    };
};

export const createManagedPeopleActionStart = () => {
    return {
        type: actionTypes.CREATE_MANAGED_PEOPLE_ACTION_START,
    };
};

export const createManagedPeopleActionSuccess = (addedAction) => {
    return {
        type: actionTypes.CREATE_MANAGED_PEOPLE_ACTION_SUCCESS,
        addedAction,
    };
};

export const createManagedPeopleActionFail = (error) => {
    return {
        type: actionTypes.CREATE_MANAGED_PEOPLE_ACTION_FAIL,
        error: error,
    };
};

export const createManagedPeopleAction = (action) => {
    return (dispatch) => {
        dispatch(createManagedPeopleActionStart());
        return ActionPlanAPI.createManagedPeopleAction(action)
            .then((res) => {
                dispatch(createManagedPeopleActionSuccess(res));
            })
            .catch((err) => {
                dispatch(createManagedPeopleActionFail(err));
            });
    };
};

export const updateActionStart = () => {
    return {
        type: actionTypes.UPDATE_ACTION_START,
    };
};

export const updateActionSuccess = (addedAction) => {
    return {
        type: actionTypes.UPDATE_ACTION_SUCCESS,
        addedAction,
    };
};

export const updateActionFail = (error) => {
    return {
        type: actionTypes.UPDATE_ACTION_FAIL,
        error: error,
    };
};

export const updateAction = (id, action) => {
    return (dispatch) => {
        dispatch(updateActionStart());
        return ActionPlanAPI.updateAction(id, action)
            .then((res) => {
                dispatch(updateActionSuccess(res));
            })
            .catch((err) => {
                dispatch(updateActionFail(err));
            });
    };
};

export const updateHideAction = (id) => {
    return (dispatch) => {
        dispatch(updateActionStart());
        return ActionPlanAPI.updateHideAction(id)
            .then((res) => {
                dispatch(updateActionSuccess(res));
            })
            .catch((err) => {
                dispatch(updateActionFail(err));
            });
    };
};

export const updateUnHideAction = (id) => {
    return (dispatch) => {
        dispatch(updateActionStart());
        return ActionPlanAPI.updateUnHideAction(id)
            .then((res) => {
                dispatch(updateActionSuccess(res));
            })
            .catch((err) => {
                dispatch(updateActionFail(err));
            });
    };
};

export const updateManagedPeopleActionStart = () => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_ACTION_START,
    };
};

export const updateManagedPeopleActionSuccess = (addedAction) => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_ACTION_SUCCESS,
        addedAction,
    };
};

export const updateManagedPeopleActionFail = (error) => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_ACTION_FAIL,
        error: error,
    };
};

export const updateManagedPeopleAction = (id, action) => {
    return (dispatch) => {
        dispatch(updateManagedPeopleActionStart());
        return ActionPlanAPI.updateManagedPeopleAction(id, action)
            .then((res) => {
                dispatch(updateManagedPeopleActionSuccess(res));
            })
            .catch((err) => {
                dispatch(updateManagedPeopleActionFail(err));
            });
    };
};

export const deleteActionStart = () => {
    return {
        type: actionTypes.DELETE_ACTION_START,
    };
};

export const deleteActionSuccess = () => {
    return {
        type: actionTypes.DELETE_ACTION_SUCCESS,
    };
};

export const deleteActionFail = (error) => {
    return {
        type: actionTypes.DELETE_ACTION_FAIL,
        error: error,
    };
};

export const deleteAction = (id) => {
    return (dispatch) => {
        dispatch(deleteActionStart());
        return ActionPlanAPI.deleteAction(id)
            .then((res) => {
                dispatch(deleteActionSuccess(res));
            })
            .catch((err) => {
                dispatch(deleteActionFail(err));
            });
    };
};

export const deleteManagedPeopleActionStart = () => {
    return {
        type: actionTypes.DELETE_MANAGED_PEOPLE_ACTION_START,
    };
};

export const deleteManagedPeopleActionSuccess = () => {
    return {
        type: actionTypes.DELETE_MANAGED_PEOPLE_ACTION_SUCCESS,
    };
};

export const deleteManagedPeopleActionFail = (error) => {
    return {
        type: actionTypes.DELETE_MANAGED_PEOPLE_ACTION_FAIL,
        error: error,
    };
};

export const deleteManagedPeopleAction = (id, email) => {
    return (dispatch) => {
        dispatch(deleteManagedPeopleActionStart());
        return ActionPlanAPI.deleteManagedPeopleAction(id, email)
            .then((res) => {
                dispatch(deleteManagedPeopleActionSuccess(res));
            })
            .catch((err) => {
                dispatch(deleteManagedPeopleActionFail(err));
            });
    };
};

export const sendUpdatedActionPlanNotificationStart = () => {
    return {
        type: actionTypes.SEND_UPDATED_ACTIONPLAN_NOTIFICATION_START,
    };
};

export const sendUpdatedActionPlanNotificationSuccess = () => {
    return {
        type: actionTypes.SEND_UPDATED_ACTIONPLAN_NOTIFICATION_SUCCESS,
    };
};

export const sendUpdatedActionPlanNotificationFail = (error) => {
    return {
        type: actionTypes.SEND_UPDATED_ACTIONPLAN_NOTIFICATION_FAIL,
        error: error,
    };
};

export const sendUpdatedActionPlanNotification = (email) => {
    return (dispatch) => {
        dispatch(sendUpdatedActionPlanNotificationStart());
        return ActionPlanAPI.sendUpdatedActionPlanNotification(email)
            .then((res) => {
                dispatch(sendUpdatedActionPlanNotificationSuccess(res));
            })
            .catch((err) => {
                dispatch(sendUpdatedActionPlanNotificationFail(err));
            });
    };
};

export const submitActionPromotionStart = () => {
    return {
        type: actionTypes.SUBMIT_ACTION_PROMOTION_START,
    };
};

export const submitActionPromotionSuccess = (actionPromotion) => {
    return {
        type: actionTypes.SUBMIT_ACTION_PROMOTION_SUCCESS,
        actionPromotion,
    };
};

export const submitActionPromotionFail = (error) => {
    return {
        type: actionTypes.SUBMIT_ACTION_PROMOTION_FAIL,
        error: error,
    };
};

export const submitActionPromotion = (actionId, description) => {
    return (dispatch) => {
        dispatch(submitActionPromotionStart());
        return ActionPlanAPI.submitActionPromotion(actionId, description)
            .then((res) => {
                dispatch(submitActionPromotionSuccess(res));
            })
            .catch((err) => {
                dispatch(submitActionPromotionFail(err));
            });
    };
};

export const submitProposeActionStart = () => {
    return {
        type: actionTypes.SUBMIT_PROPOSE_ACTION_START,
    };
};

export const submitProposeActionSuccess = (proposedAction) => {
    return {
        type: actionTypes.SUBMIT_PROPOSE_ACTION_SUCCESS,
        proposedAction,
    };
};

export const submitProposeActionFail = (error) => {
    return {
        type: actionTypes.SUBMIT_PROPOSE_ACTION_FAIL,
        error: error,
    };
};

export const submitProposeAction = (actionId, personEmail, updateProfileEvidenceProposalSkills) => {
    return (dispatch) => {
        dispatch(submitProposeActionStart());
        return ActionPlanAPI.submitProposeAction(actionId, personEmail, updateProfileEvidenceProposalSkills)
            .then((res) => {
                dispatch(submitProposeActionSuccess(res));
            })
            .catch((err) => {
                dispatch(submitProposeActionFail(err));
            });
    };
};

export const acceptUpdateProfileActionProposalStart = () => {
    return {
        type: actionTypes.ACCEPT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_START,
    };
};

export const acceptUpdateProfileActionProposalSuccess = () => {
    return {
        type: actionTypes.ACCEPT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_SUCCESS,
    };
};

export const acceptUpdateProfileActionProposalFail = (error) => {
    return {
        type: actionTypes.ACCEPT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_FAIL,
        error: error,
    };
};

export const acceptUpdateProfileActionProposal = (actionId, comment, timezoneOffset) => {
    return (dispatch) => {
        dispatch(acceptUpdateProfileActionProposalStart());
        return ActionPlanAPI.acceptUpdateProfileFromActionProposal(actionId, comment, timezoneOffset)
            .then((res) => {
                dispatch(acceptUpdateProfileActionProposalSuccess());
            })
            .catch((err) => {
                dispatch(acceptUpdateProfileActionProposalFail(err));
            });
    };
};

export const rejectUpdateProfileActionProposalStart = () => {
    return {
        type: actionTypes.REJECT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_START,
    };
};

export const rejectUpdateProfileActionProposalSuccess = () => {
    return {
        type: actionTypes.REJECT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_SUCCESS,
    };
};

export const rejectUpdateProfileActionProposalFail = (error) => {
    return {
        type: actionTypes.REJECT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_FAIL,
        error: error,
    };
};

export const rejectUpdateProfileActionProposal = (actionId, comment, timezoneOffset) => {
    return (dispatch) => {
        dispatch(rejectUpdateProfileActionProposalStart());
        return ActionPlanAPI.rejectUpdateProfileFromActionProposal(actionId, comment, timezoneOffset)
            .then((res) => {
                dispatch(rejectUpdateProfileActionProposalSuccess());
            })
            .catch((err) => {
                dispatch(rejectUpdateProfileActionProposalFail(err));
            });
    };
};
