import * as actions from "../src/store/actions/authAction";
import * as companyActions from "../src/store/actions/companyAction";
import * as configActions from "../src/store/actions/configAction";
import * as contentActions from "../src/store/actions/contentAction";
import * as languageActions from "../src/store/actions/languageAction";
import * as skillProfileActions from "../src/store/actions/skillProfileAction";
import * as adminPersonActions from "../src/store/admin/actions/adminPersonActions";
import ProtectedRoute from "./../src/components/routes/ProtectedRoute";
import CacheBuster from "./CacheBuster";
import ForgotPassword from "./components/ForgotPassword";
import Login from "./components/Login";
import Logout from "./components/Logout";
import ResetPassword from "./components/ResetPassword";
import ResetPasswordConfirmation from "./components/ResetPasswordConfirmation";
import TeamTabs from "./components/TeamTabs";
import AdminHome from "./components/admin/AdminHome";
import MySetting from "./components/mysetting/MySetting";
import LoggedInRoute from "./components/routes/LoggedInRoute";
import How from "./components/welcome/How";
import "./custom.css";
import ProfilePage from "./pages/ProfilePage";
import SelfAssessmentPage from "./pages/SelfAssessmentPage";
import ThankYouPage from "./pages/ThankYou";
import Wallet from "./pages/Wallet";
import WelcomePage from "./pages/WelcomePage";
import { HubConnectionBuilder } from "@aspnet/signalr";
import React, { Component } from "react";
import { AlertList } from "react-bs-notifier";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router";
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";
import { Userpilot } from "userpilot";

const messageHubHostname = process.env.NODE_ENV === "development" ? process.env.REACT_APP_MESSAGE_HUB_ENDPOINT : "/messagehub";
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;

const FileDownload = require("js-file-download");

const userPilotToken = process.env.REACT_APP_USER_PILOT_TOKEN;
const signalRTimerMaxCount = 300;

Userpilot.initialize(userPilotToken);
class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            loading: true,
            languageLoading: true,
            contentLoading: true,
            configLoading: true,
            alerts: [],
            language: null,
            showActiveUsersDialog: false,
            signalRTimerStarted: false,
            signalRTimerCount: 0,
        };

        this.connectSignalR = this.connectSignalR.bind(this);
        this.disconnectSignalR = this.disconnectSignalR.bind(this);

        this.generateAlert = this.generateAlert.bind(this);
        this.dismisAlert = this.dismisAlert.bind(this);
    }

    generateAlert = (type, message) => {
        const alerts = this.state.alerts;

        this.setState({
            alerts: [
                ...alerts,
                {
                    id: new Date().getTime(),
                    type: type,
                    message: message,
                },
            ],
        });
    };

    dismisAlert = (alert) => {
        const alerts = this.state.alerts;
        const idx = alerts.indexOf(alert);
        if (idx >= 0) {
            this.setState({
                alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)],
            });
        }
    };

    componentDidMount() {
        if (!this.props.defaultCompany) {
            this.props.onCompany.getDefaultCompany().then(() => {});
        }

        if (!this.props.brandingLogoChecked) {
            this.props.onCompany.getCompanyBrandingLogo().then(() => {});
        }

        let languageName = localStorage.getItem("language");

        this.props.authAction.checkLoginCookiesState().then(() => {
            this.connectSignalR();

            if (this.props.loginResult) {
                Userpilot.identify(this.props.loginResult.personId, {
                    name: `${this.props.loginResult.firstName} ${this.props.loginResult.lastName}`,
                    email: this.props.loginResult.email,
                });
            }

            if (!this.props.samlEnabled || !this.props.containerConfig) {
                Promise.all([this.props.onConfig.getSamlEnabledStatus(), this.props.onConfig.getContainerConfig()]).then(() => {
                    if (this.props.containerConfig) {
                        if (this.props.containerConfig.defaultBaseUrl !== window.location.origin + "/") {
                            window.location = this.props.containerConfig.defaultBaseUrl;
                        }
                    }

                    this.setState({
                        configLoading: this.props.configLoading,
                    });
                });
            } else {
                this.setState({ configLoading: false });
            }

            this.props.onLanguage.getLanguages().then(() => {
                if (!this.props.languageLoading) {
                    let languages = this.props.languages;
                    let language = null;

                    if (!languages || languages.length == 0) {
                        languages.push({
                            code: "GB",
                            name: "English",
                            rightAlign: false,
                        });
                    }

                    if (languageName) {
                        language = languages.find((it) => it.name == languageName);
                    } else {
                        language = languages.find((it) => it.name == defaultLanguage);
                    }

                    if (language) {
                        this.setCurrentLanguage(language);
                    }

                    this.setState({ languageLoading: this.props.languageLoading });

                    this.props.onContent.getContentTranslations(language.id, "SfiaAll-Menu-SfiaProfileMenu-Registration-Login-ForgotPassword").then(() => {
                        this.setState({ contentLoading: this.props.contentLoading });
                    });
                }
            });

            let authMode = localStorage.getItem("authMode");
            authMode = authMode ? authMode : "Planner";

            let authModes = authMode.split(",").map((item) => item.trim());

            if (authModes.includes("Admin")) {
                if (!this.props.pageAssignments) {
                    this.props.onAdminPerson.getPageAssignments().then(() => {});
                }

                if (!this.props.columnDisplaySettings) {
                    this.props.onAdminPerson.getColumnDisplaySettings().then(() => {});
                }

                if (!this.props.activeUserCountStatusFetched) {
                    this.props.onConfig.getActiveUserCountStatus().then(() => {
                        if (!this.props.activeUserCountStatusLoading && this.props.activeUserCount && this.props.showActiveUserCountNotification) {
                            if (this.props.activeUserCount > 1 && this.props.showActiveUserCountNotification) {
                                this.setState({ showActiveUsersDialog: true });
                            } else {
                                this.setState({ showActiveUsersDialog: false });
                            }
                        }
                    });
                }

                const pathName = window.location.pathname;
                const paths = pathName.split("/");
                const isDownloadReport = pathName.includes("/admin/endorsements/download");

                if (isDownloadReport && paths && paths.length > 0) {
                    if (paths.length >= 4) {
                        var token = paths[4];
                        if (token) {
                            this.props.onAdminPerson.downloadProfileReports(token).then(() => {
                                if (!this.props.loadingPrint) {
                                    if (this.props.printData) {
                                        FileDownload(this.props.printData, `SkillsTx_Persons_Profiles.zip`);
                                    } else {
                                        this.generateAlert("danger", "Profile reports not found");
                                    }
                                }
                            });
                        }
                    }
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.isAuthenticated !== prevProps.isAuthenticated && this.props.isAuthenticated && this.props.authMode) {
            let authMode = this.props.authMode;
            authMode = authMode ? authMode : "Planner";

            let authModes = authMode.split(",").map((item) => item.trim());

            if (authModes.includes("Admin")) {
                if (!this.props.pageAssignments) {
                    this.props.onAdminPerson.getPageAssignments().then(() => {});
                }

                if (!this.props.columnDisplaySettings) {
                    this.props.onAdminPerson.getColumnDisplaySettings().then(() => {});
                }

                if (!this.props.activeUserCountStatusFetched) {
                    this.props.onConfig.getActiveUserCountStatus().then(() => {
                        if (!this.props.activeUserCountStatusLoading && this.props.activeUserCount && this.props.showActiveUserCountNotification) {
                            if (this.props.activeUserCount > 1 && this.props.showActiveUserCountNotification) {
                                this.setState({ showActiveUsersDialog: true });
                            } else {
                                this.setState({ showActiveUsersDialog: false });
                            }
                        }
                    });
                }
            }

            this.disconnectSignalR();
            setTimeout(() => {
                this.connectSignalR();
            }, 100);

            if (this.props.loginResult) {
                Userpilot.identify(this.props.loginResult.personId, {
                    name: `${this.props.loginResult.firstName} ${this.props.loginResult.lastName}`,
                    email: this.props.loginResult.email,
                });
            }
        }

        if (this.props.containerConfig !== prevProps.containerConfig && this.props.containerConfig) {
            if (this.props.containerConfig.ssoMembeeEnable != true) {
                const divMembeeLogin = document.getElementById("divMembeeLogin");
                if (divMembeeLogin) {
                    divMembeeLogin.outerHTML = "";
                }
            }
        }
    }

    UNSAFE_componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            Userpilot.reload();
        });
    }

    componentWillUnmount() {
        this.unlisten();
        this.disconnectSignalR();
    }

    connectSignalR() {
        const personId = localStorage.getItem("personId");

        if (personId && !this.connection && this.props.isAuthenticated) {
            this.connection = new HubConnectionBuilder().withUrl(messageHubHostname).build();

            this.connection.on(`UpdateSkillsNotification${personId}`, (response) => {
                console.log("response", response);
                if (response.action == "UpdateMyProfiles") {
                    if (response.status == "Success") {
                        let udpatedSkills = response.data;
                        this.props.onSkillProfile.updateMySkillProfileSuccess(udpatedSkills);
                    } else {
                        this.props.onSkillProfile.updateMySkillProfileFail(response.message);
                    }
                }
            });

            this.connection.start().catch((err) => console.error(err.toString()));
            this.startTimer();
        }
    }

    disconnectSignalR() {
        if (this.connection) {
            this.connection.stop().then(() => {
                this.connection = null;
            });
        }
    }

    LoadingSpinner() {
        return (
            <div className="p-grid p-align-center vertical-container">
                <div className="layout-wrapper">
                    <div className="p-grid">
                        <div className="p-col-12" style={{ position: "fixed", left: "50%", top: "50%" }}>
                            <Spinner color="dark" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    setCurrentLanguage = (language) => {
        if (language && language != undefined) {
            localStorage.setItem("language", language.name);
            localStorage.setItem("languageId", language.id);

            this.setState({ language: language, languageLoading: true });

            Promise.resolve(this.props.onLanguage.setLanguage(language)).then(() => {
                this.setState({ languageLoading: false });

                this.props.onContent.getContentTranslations(language.id, "SfiaAll-Menu-SfiaProfileMenu-Registration-Login-ForgotPassword-ResetPassword").then(() => {
                    this.setState({ contentLoading: this.props.contentLoading });
                });
            });
        }
    };

    renderActiveUsersDialog = () => {
        return (
            <Modal isOpen={this.state.showActiveUsersDialog}>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        {this.props.activeUserCount >= 1.1 ? (
                                            <React.Fragment>
                                                You have breached your license count by greater than 10%.
                                                <br />
                                                Please contact your SkillsTX or SkillsTX partner representative as soon as possible to prevent service restrictions
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                You have more active users than licenses.
                                                <br />
                                                Please contact your SkillsTX or SkillsTX partner representative to arrange a true-up
                                            </React.Fragment>
                                        )}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.setState({ showActiveUsersDialog: false });
                        }}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    startTimer = () => {
        const signalRTimerStarted = this.state.signalRTimerStarted;

        if (!signalRTimerStarted) {
            setInterval(() => {
                let signalRTimerCount = this.state.signalRTimerCount + 1;

                if (signalRTimerCount >= signalRTimerMaxCount && !this.props.skillsProfileUpdating) {
                    signalRTimerCount = 0;

                    this.disconnectSignalR();
                    setTimeout(() => {
                        this.connectSignalR();
                    }, 100);
                }

                this.setState({ signalRTimerCount: signalRTimerCount });
            }, 1000);

            this.setState({ signalRTimerStarted: true });
        }
    };

    render() {
        let authMode = localStorage.getItem("authMode");
        authMode = authMode ? authMode : "Planner";

        let redirectUrl = localStorage.getItem("redirectUrl") ? (localStorage.getItem("redirectUrl") != "" && localStorage.getItem("redirectUrl") != "null" ? localStorage.getItem("redirectUrl") : null) : null;
        let isLogout = localStorage.getItem("isLogout") ? (localStorage.getItem("isLogout") == "TRUE" ? true : false) : false;

        let authModes = authMode.split(",").map((item) => item.trim());

        return (
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        refreshCacheAndReload();
                    }

                    return (
                        <div>
                            {this.props.containerConfig && this.props.containerConfig.enableWalkme && (
                                <Helmet>
                                    <script type="text/javascript" src="assets/scripts/walkme.js"></script>
                                </Helmet>
                            )}
                            <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                            {this.renderActiveUsersDialog()}
                            {this.state.languageLoading || this.state.contentLoading || this.state.configLoading ? (
                                this.LoadingSpinner()
                            ) : (
                                <Switch>
                                    <Route exact path="/" component={() => <WelcomePage language={this.state.language} redirectUrl={redirectUrl} isLogout={isLogout} setCurrentLanguage={this.setCurrentLanguage} />} />
                                    <Route path="/welcome/how" exact component={How} />
                                    <Route path="/thankyou" render={(props) => <ThankYouPage language={this.state.language} setCurrentLanguage={this.setCurrentLanguage} />} />
                                    <Route path="/survey/" render={(props) => <SelfAssessmentPage language={this.state.language} setCurrentLanguage={this.setCurrentLanguage} />} />

                                    {!this.props.loginWithTeams && <Route path="/logout" exact component={() => <Logout disconnectSignalR={this.disconnectSignalR} />} />}
                                    {/* <Route path='/SfiaProfile/' component={ProfilePage} /> */}
                                    <ProtectedRoute
                                        disconnectSignalR={this.disconnectSignalR}
                                        isAuthenticated={(authModes.includes("Planner") || authModes.includes("Admin")) && this.props.isAuthenticated}
                                        authMode={authMode}
                                        path="/SfiaProfile"
                                        key="/SfiaProfile"
                                        redirectUrl={redirectUrl}
                                        isLogout={isLogout}
                                        language={this.state.language}
                                        setCurrentLanguage={this.setCurrentLanguage}
                                        component={ProfilePage}
                                    />

                                    <ProtectedRoute
                                        disconnectSignalR={this.disconnectSignalR}
                                        isAuthenticated={authModes.includes("Admin") && this.props.isAuthenticated}
                                        authMode={authMode}
                                        path="/admin"
                                        key="/admin"
                                        redirectUrl={redirectUrl}
                                        isLogout={isLogout}
                                        language={this.state.language}
                                        setCurrentLanguage={this.setCurrentLanguage}
                                        component={AdminHome}
                                    />
                                    <ProtectedRoute
                                        disconnectSignalR={this.disconnectSignalR}
                                        isAuthenticated={authModes.includes("Admin") && this.props.isAuthenticated}
                                        authMode={authMode}
                                        path="/downloadprofilereport/:token"
                                        exact
                                        language={this.state.language}
                                        setCurrentLanguage={this.setCurrentLanguage}
                                        appGenerateAlert={this.generateAlert}
                                        component={AdminHome}
                                    />
                                    <ProtectedRoute
                                        disconnectSignalR={this.disconnectSignalR}
                                        isAuthenticated={(authModes.includes("Planner") || authModes.includes("Admin")) && this.props.isAuthenticated}
                                        authMode={authMode}
                                        path="/mysetting"
                                        key="/mysetting"
                                        language={this.state.language}
                                        setCurrentLanguage={this.setCurrentLanguage}
                                        component={MySetting}
                                    />
                                    <LoggedInRoute
                                        isAuthenticated={this.props.isAuthenticated}
                                        redirectUrl={redirectUrl}
                                        isLogout={isLogout}
                                        authMode={authMode}
                                        connectSignalR={this.connectSignalR}
                                        path="/login"
                                        exact
                                        language={this.state.language}
                                        setCurrentLanguage={this.setCurrentLanguage}
                                        component={Login}
                                    />

                                    <LoggedInRoute
                                        isAuthenticated={this.props.isAuthenticated}
                                        redirectUrl={redirectUrl}
                                        isLogout={isLogout}
                                        authMode={authMode}
                                        connectSignalR={this.connectSignalR}
                                        path="/loginmembee"
                                        exact
                                        language={this.state.language}
                                        setCurrentLanguage={this.setCurrentLanguage}
                                        boolLoginFail={false}
                                        component={Login}
                                        boolLoginMembee={true}
                                    />

                                    <LoggedInRoute isAuthenticated={this.props.isAuthenticated} authMode={authMode} connectSignalR={this.connectSignalR} path="/login/fail" exact language={this.state.language} setCurrentLanguage={this.setCurrentLanguage} boolLoginFail={true} component={Login} />

                                    <LoggedInRoute isAuthenticated={this.props.isAuthenticated} authMode={authMode} connectSignalR={this.connectSignalR} path="/login/:token" exact language={this.state.language} setCurrentLanguage={this.setCurrentLanguage} component={Login} />

                                    <LoggedInRoute isAuthenticated={this.props.isAuthenticated} authMode={authMode} path="/forgotpassword" exact language={this.state.language} setCurrentLanguage={this.setCurrentLanguage} component={ForgotPassword} />
                                    <Route path="/resetpasswordconfirmation" exact render={(props) => <ResetPasswordConfirmation language={this.state.language} setCurrentLanguage={this.setCurrentLanguage} />} />
                                    <Route path="/wallet/:id" exact component={Wallet} />
                                    <Route path="/resetpassword" exact render={(props) => <ResetPassword language={this.state.language} setCurrentLanguage={this.setCurrentLanguage} generateAlert={this.generateAlert} dismisAlert={this.dismisAlert} />} />
                                    <Route path="/teamtabs" exact component={TeamTabs} />
                                </Switch>
                            )}
                        </div>
                    );
                }}
            </CacheBuster>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,
        loginResult: state.auth.loginResult,
        authMode: state.auth.authMode,
        redirectUrl: state.auth.redirectUrl,
        isLogout: state.auth.isLogout,
        //stateRoles: state.auth.roles,
        loading: state.auth.loading,
        languageLoading: state.language.loading,
        languages: state.language.languages,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        samlEnabled: state.config.samlEnabled,
        defaultCompany: state.company.defaultCompany,
        brandingLogoImage: state.company.brandingLogoImage,

        containerConfig: state.config.containerConfig,
        configLoading: state.config.loading,

        activeUserCount: state.config.activeUserCount,
        showActiveUserCountNotification: state.config.showActiveUserCountNotification,
        activeUserCountStatusFetched: state.config.activeUserCountStatusFetched,
        activeUserCountStatusLoading: state.config.activeUserCountStatusLoading,

        pageAssignments: state.adminPerson.pageAssignments,
        columnDisplaySettings: state.adminPerson.columnDisplaySettings,
        loginWithTeams: state.auth.loginWithTeams,

        loadingPrint: state.adminPerson.loadingPrint,
        printData: state.adminPerson.printData,
        personsError: state.adminPerson.error,

        receiveLicenseNotification: state.auth.receiveLicenseNotification,
        lastActiveUserNotification: state.auth.lastActiveUserNotification,

        skillsProfileUpdating: state.skillProfile.profileUpdating,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        authAction: bindActionCreators(actions, dispatch),
        onSkillProfile: bindActionCreators(skillProfileActions, dispatch),
        onLanguage: bindActionCreators(languageActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
        onCompany: bindActionCreators(companyActions, dispatch),
        onAdminPerson: bindActionCreators(adminPersonActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
